import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Card from 'react-myoneui/lib/Card';
import { Row, Col, CircleImage } from 'react-myoneui';
import Button from 'react-myoneui/lib/Button';
import AboutInfoControl from './AboutInfoControl';
import AboutCircleInfo from './AboutCircleInfo';
import { getProfileCustomField } from '../../../../api';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import { editPencilIcon } from '../../../../constants/icons';
import { formatDate } from '../../../../utils/formatTime';
import admin from '../../../../routes/admin';
import { useEffect } from 'react';
import { AES, enc } from 'crypto-js';
import { decryptData, encryptData } from '../../../../lib/common';
import { activeRole } from '../../../../redux/ActiveRoleSlice';
import { formatDateddMMyyy } from '../../../../utils/formatTime';
import ls from 'localstorage-slim';
import { secretkey } from '../../../../constants/config';
import { useParams } from 'react-router-dom';
// enable global encryption
ls.config.encrypt = true;
function AboutInfoBlock({ user, assignedPrograms, idStatus }) {
  const [memberCustomInfo, setMemberCustomInfo] = useState({ ...user });
  const [allCustomFields, setAllCustomFields] = useState([]);
  const [tempCustomFields, setTempCustomFields] = useState([]);
  const memberDetails = JSON.parse(ls.get('memberDetails'));
  const [memberCustomFields, setMemberCustomFields] = useState([]);
  const { id } = useParams();
  const memberRoles = memberDetails && memberDetails?.roles?.length > 0 ? memberDetails.roles.map((items) => items.name) : [];
  const navigate = useNavigate();
  const navigateRoles = (url) => {
    navigate(url);
  };

  const { pathname } = useLocation();
  const activeRole = JSON.parse(ls.get('activeRole'));
  const pathRole = pathname.split('/')[1];
  const role = ls.get('role');
  const isTutor = role === 'tutor' && pathRole === 'tutor' && activeRole.identifier === 'tutor';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const isAssessor = role === 'assessor' && pathRole === 'assessor' && activeRole.identifier === 'assessor';
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathRole === 'eqv' && activeRole.identifier === 'eqv';
  const currentActiveRole = isTutor ? 'tutor' : isAdmin ? 'admin' : isLearner ? 'learner' : isAssessor ? 'assessor' : isIQV ? 'iqv' : isEQV ? 'eqv' : 'superadmin';

  const encryptedUserId = user?.id ? encryptData(user.id) : encryptData(id);
  const location = useLocation();

  const isWordPresentInRoute = (word) => {
    return location.pathname.includes(word);
  };

  // Example usage
  const wordToCheck = 'users';
  const isViewedFromUser = isWordPresentInRoute(wordToCheck);
  const fetchCustomFields = async () => {
    let result = await getProfileCustomField();
    let data = [...result.data.data];
    data = data.map((item) => {
      let obj = { ...item };
      obj.value = '';
      return obj;
    });

    // Set all CustomFields
    const individualMemberRoles = memberCustomInfo.roles.map((item) => item.name);
    let filterAdmin = data.filter((item) => item.roles.find((theadmin) => (idStatus ? memberRoles.includes(theadmin.role.name) : individualMemberRoles.includes(theadmin.role.name))));
    const membersCustomfield = idStatus
      ? JSON.parse(memberDetails?.custom_fields?.length > 0 ? memberDetails?.custom_fields : null)
      : JSON.parse(memberCustomInfo?.custom_fields?.length > 0 ? memberCustomInfo?.custom_fields : null);
    filterAdmin = filterAdmin.map((item) => {
      const filterobject = membersCustomfield ? membersCustomfield.find((values) => values.id == item.id) : null;
      let itemClone = { ...item };
      itemClone.value = filterobject?.value ? filterobject.value : '';
      return itemClone;
    });
    setMemberCustomFields(filterAdmin);
    setAllCustomFields(data);
    setTempCustomFields(memberCustomInfo.custom_fields ? JSON.parse(memberCustomInfo.custom_fields) : []);
  };

  useEffect(() => {
    fetchCustomFields();
  }, []);

  return (
    <Card
      className="border-0"
      id="about"
    >
      <Card.Header className="about-activity-header">
        <strong id="about">About</strong>

        {idStatus && !isViewedFromUser && (
          <Button
            variant="leading-icon"
            className="leading-icon"
            onClick={() => navigateRoles(`/${currentActiveRole}/profile/edit-profile/${encryptedUserId}`)}
          >
            {editPencilIcon} Edit
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        <div className="bg-gray about-info-block">
          <h4>Basic Details</h4>
          <Row>
            <Col
              xl={4}
              sm={6}
              className="mb-4"
            >
              <AboutInfoControl
                name="Full Name"
                description={idStatus ? memberDetails.first_name + ' ' + memberDetails.last_name : user.first_name + ' ' + user.last_name}
              />
            </Col>
            <Col
              xl={4}
              sm={6}
              className="mb-4"
            >
              <AboutInfoControl
                name="Email"
                description={idStatus ? memberDetails.email : user.decrypted_email}
              />
            </Col>
            <Col
              xl={4}
              sm={6}
              className="mb-4"
            >
              <AboutInfoControl
                name="Phone Number"
                description={idStatus ? `${memberDetails.phone_country_code}  ${memberDetails?.phone_no ? user.phone_no : ''}` : `${user.phone_country_code}  ${user?.phone_no ? user.phone_no : ''}`}
              />
            </Col>
            <Col
              xl={4}
              sm={6}
            >
              <AboutInfoControl
                name="Designation"
                description={
                  idStatus
                    ? memberDetails?.roles?.map((role, i) => {
                        return i >= 1 ? ', ' + role.name : role.name;
                      })
                    : user?.roles?.map((role, i) => {
                        return i >= 1 ? ', ' + role.name : role.name;
                      })
                }
              />
            </Col>
            <Col
              xl={4}
              sm={6}
              className="mb-4"
            >
              <AboutInfoControl
                name="Created At"
                description={formatDate(user.createdAt)}
              />
            </Col>
          </Row>
        </div>
        {memberCustomFields?.length ? (
          <div className="bg-gray about-info-block">
            <h4>Custom Fields</h4>
            {/* {JSON.stringify(memberCustomInfo.custom_fields)} */}
            <Row>
              {memberCustomFields.map((customFields) => (
                <Col
                  xl={4}
                  sm={6}
                  className="mb-4"
                  key={customFields.id}
                >
                  {customFields.type == 'date' ? (
                    <AboutInfoControl
                      name={customFields.label}
                      description={formatDateddMMyyy(customFields.value)}
                    />
                  ) : customFields.type == 'textarea' ? (
                    <AboutInfoControl
                      name={customFields.label}
                      description={<div dangerouslySetInnerHTML={{ __html: customFields.value }} />}
                    />
                  ) : (
                    <AboutInfoControl
                      name={customFields.label}
                      description={customFields.value}
                      type={customFields.type}
                    />
                  )}
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          ''
        )}

        <div
          className="about-info-block"
          id="activity"
        >
          <h4 id="#activity">Activity Details</h4>
          <Row>
            <Col
              xl={4}
              sm={6}
              className="mb-4"
            >
              <AboutInfoControl
                name="Onboarded"
                description={assignedPrograms.length > 0 ? formatDate(assignedPrograms[0].enrolledAt) : ''}
              />
            </Col>
            <Col
              xl={4}
              sm={6}
              className="mb-4"
            >
              <AboutInfoControl
                name="Last Active"
                description={formatDate(user.lastActive)}
              />
            </Col>
            {/* <Col xl={4} sm={6} className="mb-4">
              <AboutInfoControl name="Employer" description="Brad Pitt Construction" />
            </Col> */}
            {/* <Col xl={4} sm={6}>
              <AboutInfoControl name="Assessors" />
              <div className="about-circle-info-wrap">
                <AboutCircleInfo name="Anna Sthesia">
                  <CircleImage className="circle-wrap circle-wrap-xs bg-circle-primary" />
                </AboutCircleInfo>
                <AboutCircleInfo name="Bruce Wayne">
                  <CircleImage className="circle-wrap circle-wrap-xs bg-circle-info" />
                </AboutCircleInfo>
              </div>
            </Col> */}
          </Row>
        </div>

        {/* <div className="about-info-block">
          <h4>Address Details</h4>
          <Row>
            <Col xl={6} sm={6} className="mb-4">
              <AboutInfoControl name="Address Line 1" description="MR 23/13, Sthesia, Maruthi Nagar, Bangalore, 560033" />
            </Col>
            <Col xl={6} sm={6} className="mb-4">
              <AboutInfoControl className="opacity" name="Address Line 2 (Optional)" description="No data provided" />
            </Col>
            <Col xl={6} sm={6} className="mb-4">
              <AboutInfoControl name="City, State" description="Maruthi Nagar, Bangalore" />
            </Col>
            <Col xl={6} sm={6}>
              <AboutInfoControl name="Pincode" description="560033" />
            </Col>
          </Row>
        </div> */}
      </Card.Body>
    </Card>
  );
}

export default AboutInfoBlock;
