import Button from 'react-myoneui/lib/Button';

import { Badge } from 'react-myoneui';
import { decryptData, encryptData } from 'lib/common';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import ls from 'localstorage-slim';
import { useSelector } from 'react-redux';
import Log from 'lib/Log';
import { translate } from 'lib/common';

ls.config.encrypt = true;
function ActivitiesDetailList(props) {
  const { id, programId } = useParams();
  const userId = id || encryptData(ls.get('userid'));
  const navigate = useNavigate();
  const role = ls.get('role');
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const { activityId, title, sectionId, location, name, schedule, viewButton, handleClick, otj, hours, status, innerActivitiesDetails, can_add_operation, total_operation, canAddManualHours } = props;
  let totalHours = 0;
  let totalCompletedOperations = 0;
  if (hours) {
    const nonDeletedHours = hours.filter((hour) => !hour.deleted);
    totalHours = nonDeletedHours?.reduce((acc, hour) => hour.total_hours + acc, 0);
    totalCompletedOperations = nonDeletedHours?.reduce((acc, hour) => hour.completed_operation + acc, 0);
  }
  /* of route have id aftr lecture-list then it is member section otherwise it will be component id */
  const linkPage = () => {
    navigate(`${encryptData(otj.id)}/activitityDetail/${encryptData(activityId)}/${encryptData(sectionId)}`);
    /*
     switch (role) {
      case 'learner':
        navigate(`/${role}/program/${programId}/lecture-list/${encryptData(otj.id)}/activitityDetail/${encryptData(activityId)}/${encryptData(sectionId)}`);
        break;
      case 'iqv':
        navigate(`${encryptData(otj.id)}/activitityDetail/${encryptData(activityId)}/${encryptData(sectionId)}`);
        break;
      case 'eqv':
        navigate(`${encryptData(otj.id)}/activitityDetail/${encryptData(activityId)}/${encryptData(sectionId)}`);
        break;
      default:
        navigate(`/${role}/users/apprentices/view-profile/${userId}/program/${programId}/lecture-list/${encryptData(otj.id)}/activitityDetail/${encryptData(activityId)}/${encryptData(sectionId)}`);
        break;
    }
    */
  };
  return (
    <div
      className={`activities-detail-list ${props?.className || ''}`}
      onClick={() => handleClick && handleClick(activityId)}
    >
      {!innerActivitiesDetails ? (
        <>
          <div className="activities-detail-info activities-detail-info-width">
            {title && <h4>{title}</h4>}
            {location && <span>{location}</span>}
            {name && <span>{name}</span>}
          </div>
          <div className="activities-detail-info">
            <h4>Date</h4>
            {schedule && <span>{schedule}</span>}
          </div>
          <div className="activities-detail-info">
            <h4>Total Hours</h4>
            {<span>{totalHours}</span>}
          </div>
          {can_add_operation && (
            <div className="activities-detail-info">
              <h4>Total {translate(taxonomy)('operation')}'s</h4>
              <span>
                {totalCompletedOperations} / {total_operation}
              </span>
              {total_operation === totalCompletedOperations && <span className="ms-2">✅</span>}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="activities-detail-info w-auto">
            {title && <h4>{title}</h4>}
            <div className="d-flex gap-2 justify-content-between mb-1">
              <span className="d-flex gap-1">
                <strong>Location: </strong>
                {location && <span> {location}</span>}
              </span>
              <span className="d-flex gap-1">
                <strong>Date: </strong>
                {schedule && <span>{schedule}</span>}
              </span>
            </div>
            <span className="d-flex gap-1">
              <strong>Total Hours: </strong> {totalHours && <span>{totalHours}</span>}
            </span>
          </div>
          {can_add_operation && (
            <span className="d-flex gap-1">
              <strong>Total {translate(taxonomy)('operation')}'s:</strong>
              {totalCompletedOperations && <span>{totalCompletedOperations}</span>}
            </span>
          )}
        </>
      )}

      {(status === true || status === false) && (
        <div className="activities-detail-info">
          {/* <Badge className={status === true ? 'badge' : 'badge disapproved-btn'}>{status === true ? 'Approved' : 'Disapproved'}</Badge> */}
          <div className={status === true ? 'status-label status-approved mt-1' : 'status-label status-rejected mt-1'}>{status === true ? 'Approved' : 'Disapproved'}</div>
        </div>
      )}
      {status === null && <div className="empty-badge"></div>}
      {viewButton && (
        <Button
          variant="information-white-bg"
          className="information-white-bg btn-sm btn-round"
          onClick={() => linkPage()}
        >
          {viewButton}
        </Button>
      )}
    </div>
  );
}
export default ActivitiesDetailList;
