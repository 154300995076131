import { memo, useEffect, useState } from 'react';
import { Form, Col, FloatingLabel, Row } from 'react-myoneui';
import LabeledRating from 'components/LabeledRating';

function InputBehaviourBlockType1({ data }) {
  const [state, setState] = useState({ ...data });
  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <>
      <Row>
        <Col lg={8}>
          <Form.Label className="form-builder-label mb-1">{state?.label}</Form.Label> <br></br>
          <Form.Label className="form-builder-label mb-2">{state?.rating_label}</Form.Label>
        </Col>
        <Col lg={4}>
          <LabeledRating data={data} />
        </Col>
      </Row>
      {data?.field_type === 'behavior_block_type1' ? (
        <>
          <Form.Label className="form-builder-label">{state?.plan_label}</Form.Label>
          <FloatingLabel
            controlId="floatingInputTitle"
            label={state?.placeholder}
            className="form-builder-floating form-builder-textarea-label"
          >
            <Form.Control
              readOnly
              tabIndex="1"
              as={state?.field_type}
              placeholder={state?.placeholder ? state.placeholder : ''}
            />
          </FloatingLabel>
        </>
      ) : (
        <Row>
          <Col lg={6}>
            <Form.Label className="form-builder-label">{state?.review_label}</Form.Label>
            <FloatingLabel
              controlId="floatingReviewTitle"
              label={state?.placeholder}
              className="form-builder-floating form-builder-textarea-label"
            >
              <Form.Control
                readOnly
                tabIndex="1"
                as={state?.field_type}
                placeholder={state?.placeholder ? state.placeholder : ''}
              />
            </FloatingLabel>
          </Col>
          <Col lg={6}>
            <Form.Label className="form-builder-label">{state?.plan_label}</Form.Label>
            <FloatingLabel
              controlId="floatingInputTitle"
              label={state?.placeholder}
              className="form-builder-floating form-builder-textarea-label"
            >
              <Form.Control
                readOnly
                tabIndex="1"
                as={state?.field_type}
                placeholder={state?.placeholder ? state.placeholder : ''}
              />
            </FloatingLabel>
          </Col>
        </Row>
      )}
    </>
  );
}
export default memo(InputBehaviourBlockType1);
