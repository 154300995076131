import { ProgressBar } from 'react-myoneui';

export default function ProgramOverviewList({ title, progressVariant, progressValue, count }) {
  return (
    <div className="program-overview-list">
      <h4>{title}</h4>
      <div className="progress-wrap">
        <ProgressBar
          variant={progressVariant}
          now={progressValue}
        />
      </div>
      <span className="program-overview-count">{count}</span>
    </div>
  );
}
