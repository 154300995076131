import { memo } from 'react';
import { smallEditIcon } from 'constants/icons';
import ls from 'localstorage-slim';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { translate } from 'lib/common';
ls.config.encrypt = true;
const OTJContent = ({ requiredHours, yourHours, onShowEditBox, status, requiredOperation, totalCompletedOperation, can_add_operation }) => {
  const { pathname } = useLocation();
  const role = ls.get('role');
  const pathRole = pathname.split('/')[1];
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { can_manage_apprentices } = activeRole?.privileges;
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  return (
    <>
      <div className="required-hours-wrap">
        <span>
          Required hours - <strong>{requiredHours} Hours</strong>
          {!isLearner && can_manage_apprentices && status !== 'Completed' && (
            <span
              className="edit-hour-wrap"
              onClick={() => onShowEditBox()}
              title="Edit Required Hours"
            >
              {smallEditIcon}
            </span>
          )}
        </span>
        <span>
          Your hours - <strong>{yourHours} Hours</strong>
        </span>
        {requiredHours - yourHours > 0 && <span>&#40; You'r behind {requiredHours} hours &#41;</span>}
      </div>
      {/* operation  */}

      {/* {can_add_operation && (
        <div className="required-hours-wrap">
          <span>
            {` Required ${translate(taxonomy)('operation')}'s -`}{' '}
            <strong>
              {requiredOperation} {translate(taxonomy)('operation')}'s
            </strong>
          </span>
          <span>
            {`Your ${translate(taxonomy)('operation')}'s`} -{' '}
            <strong>
              {totalCompletedOperation} {translate(taxonomy)('operation')}'s
            </strong>
          </span>
        </div>
      )} */}
    </>
  );
};

export default memo(OTJContent);
