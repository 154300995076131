import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setElements, setMode, updateElement, setActiveEle } from '../../../redux/FormBuilderSlice';
import { Form, FloatingLabel, Button, Spinner, Row, Card } from 'react-myoneui';
import DropdownCollection from '../../../components/DropdownCollection';
import DropdownCheckboxMultiSelect from 'components/DropdownCheckboxMultiSelect';
import { editFormField } from 'api/formBuilder';
import { addIcon, binIcon } from 'constants/icons';
import { Link } from 'react-router-dom';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
ls.config.encrypt = true;
function EditDropdownElement({ data = {}, onSave, rolesList, displayRoles }) {
  const orgId = ls.get('orgId');
  const [state, setState] = useState({});
  const dispatch = useDispatch(useDispatch);
  const [loading, setLoading] = useState(false);
  const fieldWidthOptions = [
    { id: 1, name: 'Full width', value: 12 },
    { id: 2, name: 'Half width', value: 6 },
    { id: 3, name: '1/3 width', value: 4 },
    { id: 4, name: '3/4 width', value: 8 },
  ];
  const elements = useSelector((state) => state.formbuilder.elements);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [roles, setRoles] = useState([]);
  const [items, setItems] = useState(['']);

  useEffect(() => {
    setState(activeEle);
    setRoles(rolesList.filter((item) => activeEle?.editable_by.includes(item.id)).map((item) => item.name));
  }, [activeEle, rolesList]);

  const editHandler = (e) => {
    try {
      let cloneState = JSON.parse(JSON.stringify(state));
      cloneState[e.target.name] = e.target.value;
      setState(cloneState);
    } catch (error) {
      Log.error(error);
    }
  };

  const saveHander = async () => {
    try {
      let cloneElements = [...elements];
      let element = cloneElements.find((item) => item.id === state.id);
      let index = cloneElements.indexOf(element);
      cloneElements[index] = { ...state };
      setLoading('loading');
      await editFormField({ ...state });
      dispatch(setActiveEle({ ...state }));
      setLoading(true);
      dispatch(setMode('view'));
    } catch (error) {
      Log.error(error);
      setLoading(true);
    }
  };
  const changeSize = (obj) => {
    setState({ ...state, col_width: obj.value });
  };

  const onPeriodItemsChange = (item) => {
    try {
      setRoles((roles) => {
        const prevItems = [...roles];
        const index = prevItems.indexOf(item);
        if (index === -1) {
          return [...prevItems, item];
        } else {
          prevItems.splice(index, 1);
          return prevItems;
        }
      });
    } catch (err) {
      Log.error(err);
    }
  };

  useEffect(() => {
    if (roles.length > 0) {
      setState({ ...state, editable_by: rolesList.filter((item) => roles.includes(item.name)).map((item) => item.id) });
    }
  }, [roles]);

  // dropdown item
  const handleItemsNameChange = (e) => {
    const { value } = e.target;
    const index = e.target.dataset.index;

    if (index !== undefined) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index] = value;
        setState((prevFormData) => ({
          ...prevFormData,
          dropdownItems: updatedItems,
        }));
        return updatedItems;
      });
    }
  };

  const handleItemsRow = (index) => {
    setItems((prevItems) => {
      const itemsClone = [...prevItems];
      itemsClone.splice(index, 1);
      setState((prevState) => ({
        ...prevState,
        dropdownItems: itemsClone,
      }));
      return itemsClone;
    });
  };

  const addItems = () => {
    setItems((prevRules) => [...prevRules, '']);
  };

  useEffect(() => {
    setItems(state?.dropdownItems);
  }, [state]);

  return (
    <div className="form-builder-edit-option">
      <FloatingLabel
        controlId="labelId"
        label="Label *"
        className="mb-28"
      >
        <Form.Control
          type="text"
          placeholder="Label *"
          value={state.label}
          onChange={editHandler}
          name="label"
        />
      </FloatingLabel>

      {/* {state.type !== 'image' && (
        <FloatingLabel
          controlId="placeholder"
          label="Placeholder Text *"
          className="mb-28"
        >
          <Form.Control
            type="text"
            placeholder="Placeholder Text *"
            value={state.placeholder}
            onChange={editHandler}
            name="placeholder"
          />
        </FloatingLabel>
      )} */}

      {fieldWidthOptions && state.col_width && (
        <DropdownCollection
          className="mb-28"
          defaultValue={fieldWidthOptions.find((item) => item.value === state.col_width)}
          items={fieldWidthOptions}
          label={'Field Width'}
          onChange={(obj) => changeSize(obj)}
        />
      )}
      <DropdownCheckboxMultiSelect
        className="mb-4 user-max-content-dropdown"
        label="Editable By"
        name="editable_by"
        autoComplete="off"
        items={displayRoles}
        selectedItems={roles}
        onChange={onPeriodItemsChange}
      />

      <Row className="bg-gray mb-3 p-2">
        <h4 className="level_fs">Dropdown Item</h4>
        {items &&
          items?.length > 0 &&
          items.map((item, index) => {
            return (
              // <Row key={index} className="mb-1">
              //   <Col
              //     md={'3'}
              //     key={index}
              //     className="mb-1"
              //   >
              <div className="d-flex align-items-center gap-2 mb-1">
                <FloatingLabel
                  controlId={`floatingInputAdvantage${index}`}
                  label="eg: item1, item2, ..."
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={`floatingInputAdvantage${index}`}
                    data-index={index}
                    onChange={(e) => handleItemsNameChange(e)}
                    value={item}
                  />
                </FloatingLabel>
                <Link
                  to=""
                  className=""
                  onClick={() => handleItemsRow(index)}
                  data-index={index}
                >
                  {binIcon}
                </Link>
              </div>
              //   </Col>
              // </Row>
            );
          })}
        <span className="card-divider my-1"></span>
        <div className="card-link-wrap">
          <p className="help-text mb-0">Please add required items.</p>
          <Card.Link
            href="#"
            onClick={addItems}
            className="text-primary"
          >
            {addIcon}
            Add more
          </Card.Link>
        </div>
      </Row>

      <div className="button-wrap">
        <Button
          variant="secondary"
          onClick={saveHander}
          disabled={roles?.length < 1}
        >
          {loading === 'loading' && (
            <Spinner
              animation="border"
              size="sm"
            />
          )}
          Save
        </Button>
      </div>
    </div>
  );
}
export default memo(EditDropdownElement);
