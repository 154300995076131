import { memo, useEffect, useState } from 'react';
import { Form, Col, FloatingLabel, Row } from 'react-myoneui';
import LabeledRating from 'components/LabeledRating';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import Log from 'lib/Log';

function InputBehaviourBlockType1({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();

  const valueControl = (e, type) => {
    try {
      let cloneFormData = JSON.parse(JSON.stringify(formData));
      let obj = cloneFormData.find((item) => item.id === e.target.id);
      let index = cloneFormData.indexOf(obj);
      type === 'type1' ? (obj.desc = e.target.value) : (obj.desc2 = e.target.value);
      cloneFormData[index] = obj;
      dispatch(storeFormData(cloneFormData));
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <>
      <Row className="mt-4">
        <Col lg={8}>
          <Form.Label className="form-builder-label mb-1">{state?.label}</Form.Label> <br></br>
          <Form.Label className="form-builder-label mb-2">{state?.rating_label}</Form.Label>
        </Col>
        <Col
          xl={4}
          lg={12}
          className={mode === 'preview' ? 'disabled' : mode === 'readonly' ? 'disabled' : ''}
        >
          <LabeledRating data={data} />
        </Col>
      </Row>
      {data?.field_type === 'behavior_block_type1' ? (
        <>
          <Form.Label className="form-builder-label">{state?.plan_label}</Form.Label>
          <FloatingLabel
            controlId="floatingInputTitle"
            label={state?.placeholder && state?.desc?.length === 0 ? state.placeholder : ''}
          >
            <Form.Control
              className={`${mode === 'preview' ? 'disabled' : mode === 'readonly' ? 'disabled' : ''} ${state?.value?.length > 0 && 'pt-2'} behaviour-block-textarea`}
              id={state?.id}
              as="textarea"
              tabIndex="4"
              rows="4"
              value={state?.desc}
              onChange={(e) => valueControl(e, 'type1')}
              placeholder={state?.placeholder ? state.placeholder : ''}
            />
          </FloatingLabel>
        </>
      ) : (
        <Row>
          <Col lg={6}>
            <Form.Label className="form-builder-label">{state?.review_label}</Form.Label>
            <FloatingLabel
              controlId="floatingInputTitle"
              label={state?.placeholder && state?.desc?.length === 0 ? state.placeholder : ''}
            >
              <Form.Control
                className={`${mode === 'preview' ? 'disabled' : mode === 'readonly' ? 'disabled' : ''} ${state?.value?.length > 0 && 'pt-2'} behaviour-block-textarea`}
                id={state?.id}
                as="textarea"
                tabIndex="4"
                rows="4"
                value={state?.desc}
                onChange={(e) => valueControl(e, 'type1')}
                placeholder={state?.placeholder ? state.placeholder : ''}
              />
            </FloatingLabel>
          </Col>
          <Col lg={6}>
            <Form.Label className="form-builder-label">{state?.plan_label}</Form.Label>
            <FloatingLabel
              controlId="floatingInputTitle"
              label={state?.placeholder && state?.desc?.length === 0 ? state.placeholder : ''}
            >
              <Form.Control
                className={`${mode === 'preview' ? 'disabled' : mode === 'readonly' ? 'disabled' : ''} ${state?.value?.length > 0 && 'pt-2'} behaviour-block-textarea`}
                id={state?.id}
                as="textarea"
                tabIndex="4"
                rows="4"
                value={state?.desc2}
                onChange={(e) => valueControl(e, 'type2')}
                placeholder={state?.placeholder ? state.placeholder : ''}
              />
            </FloatingLabel>
          </Col>
        </Row>
      )}
    </>
  );
}
export default memo(InputBehaviourBlockType1);
