import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import DropdownCollection from 'components/DropdownCollection';
import Log from 'lib/Log';

function InputDropdown({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();

  const valueControl = (e) => {
    try {
      let cloneFormData = JSON.parse(JSON.stringify(formData));
      let obj = cloneFormData.find((item) => item.id === state.id);
      let index = cloneFormData.indexOf(obj);
      obj.value = e.id;
      cloneFormData[index] = obj;
      dispatch(storeFormData(cloneFormData));
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <>
      <Col
        className="py-2"
        md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={state?.label + state?.field}
          className="form-builder-label"
        >
          {state?.label}
        </Form.Label>
        <DropdownCollection
          // label={state?.label}
          id={state?.id}
          items={state?.dropdownItems.map((list) => ({ name: list, id: list }))}
          disable={mode === 'preview' || mode === 'readonly' ? 'disabled' : ''}
          onChange={valueControl}
          // readOnly={mode === 'readonly'}
          defaultValue={{ name: state?.value }}
        />
      </Col>
    </>
  );
}
export default memo(InputDropdown);
