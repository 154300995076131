import React, { useEffect, useState, memo } from 'react';
import { translate } from 'lib/';
import { formatDate } from 'utils/formatTime';
import { formatUTCDate } from 'utils/formatTime';
import { useSelector, useDispatch } from 'react-redux';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import { Row, Col, Form, Card, Spinner } from 'react-myoneui';
import Button from 'react-myoneui/lib/Button';
import 'react-quill/dist/quill.snow.css';
import OberservationEvidence from 'components/learners/OberservationEvidence';
import DmsUploadWidget from './DmsUploadWidget';
import Badge from 'react-myoneui/lib/Badge';
// import { setConfirmBoxStatus } from 'redux/confirmBox';
import { blackArrowLeftIcon, addIcon, subtractionIcon, videoPlayIcon, sendIcon } from 'constants/icons';
import { addEvidences, deleteEvidences, getMemberSection } from 'api';
import { setActiveSection, setToastBoxDescription, setToastBoxStatus, storeSections, setSelectedProgramComponent, storeMemberProgram, setFirstCol, setSecondCol } from '../../../redux';
import { updateLastAssedAt, saveObservation, setStatusComplete, sendReminder } from 'api/learner/section';
import AddTarget from './Target/AddTarget';
import DOMPurify from 'dompurify';
import TextEditor from 'components/TextEditor';
import ViewModel from '../components/ViewModal';
import SubmitModal from 'components/learners/SubmitModal';
import MultipleComponentOffCanvas from 'components/multipleComponent';
import { useLocation } from 'react-router-dom';
import { ConfirmBox } from 'components/ConfirmBox';
import ls from 'localstorage-slim';
import MarkAsCompleteModal from 'components/learners/MarkAsCompleteModal';
import Log from 'lib/Log';
import { dms_base_url } from 'constants/config';
import { InputPopUpBox } from 'components/InputPopUpBox';

// enable global encryption
ls.config.encrypt = true;
function LectureObservationOffCanvas(props) {
  const purify = DOMPurify(window);
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const { show, onClose, data, onChange, column, isRemind } = props;
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [load, setLoad] = useState(false);
  const orgId = ls.get('orgId');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const activeRole = JSON.parse(ls.get('activeRole'));
  const selectedSection = useSelector((state) => state?.sections?.active_section);
  // const confirmBoxStatus = useSelector((state) => state?.confirmBox?.status);
  const section = useSelector((state) => (state?.sections?.active_section?.member_section?.section ? state.sections.active_section.member_section.section : null));
  const status = useSelector((state) => (state?.sections.active_section?.member_section?.status ? state?.sections.active_section?.member_section?.status : null));
  const isSubmitted = useSelector((state) => state?.sections?.active_section?.member_section?.status === 'Submitted');
  const isCompleted = useSelector((state) => state?.sections?.active_section?.member_section?.status === 'Completed');

  const observation_by = useSelector((state) => state?.sections?.active_section?.member_section?.observation_by);
  const observation_at = useSelector((state) => state?.sections?.active_section?.member_section?.observation_at);
  const observation = useSelector((state) => state?.sections?.active_section?.member_section?.observation);
  const evidences = useSelector((state) => state?.sections?.active_section?.member_section?.evidences);
  const { can_manage_apprentices, enable_feedback_to_learner, can_create_smart_target, can_view_smart_target, enable_document_bank } = activeRole?.privileges;
  const [markToggle, setMarkToggle] = useState(false);
  const [programSubmitToggle, setProgramSubmitToggle] = useState(false);
  const [addTargetShow, setAddTargetShow] = useState(false);
  const [multipleComponentShow, setMultipleComponentShow] = useState(false);
  const member_program = useSelector((state) => state.memberProgram);
  const targets = useSelector((state) => state?.sections?.active_section?.member_section.targets);
  const [evidenceToRemove, setEvidenceToRemove] = useState({});
  const [fileType, setFileType] = useState();
  const [fileLink, setFileLink] = useState();
  const [fileName, setFileName] = useState();
  const active_column = useSelector((state) => state.sections.active_column);
  const active_first = useSelector((state) => state.sections.active_first);
  const active_second = useSelector((state) => state.sections.active_second);
  const third_second = useSelector((state) => state.sections.third_second);
  const firstCol = useSelector((state) => state.sections.firstCol);
  const secondCol = useSelector((state) => state.sections.secondCol);

  const sections = useSelector((state) => state.sections.sections);
  const [saveAsDraftLoder, setsaveAsDraftLoder] = useState(false);
  const [toEvidences, setToEvidences] = useState([]);
  const [dmsStatus, setDmsStatus] = useState(false);
  const [confirmBox, setConfirmBox] = useState({
    name: '',
    status: false,
    OkText: 'Yes, Confirm',
    CancelText: 'No, Cancel',
    question: '',
  });
  const [showModalView, setShowModalView] = useState(false);
  const [confirmBoxStatus, setConfirmBoxStatus] = useState(false);

  //reminder
  const [reminder, setReminder] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [remindLoading, setRemindLoading] = useState(false);
  const [note, setNote] = useState('');

  const role = ls.get('role');
  const { pathname } = useLocation();
  const pathRole = pathname.split('/')[1];
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathRole === 'eqv' && activeRole.identifier === 'eqv';

  const memberProgram = useSelector((state) => state.memberProgram);
  const clonedMemberProgram = JSON.parse(JSON.stringify(memberProgram));
  const observationHandler = (data) => {
    try {
      const cloneActiveSection = JSON.parse(JSON.stringify(selectedSection));
      cloneActiveSection.member_section.observation = data;
      dispatch(setActiveSection(cloneActiveSection));
    } catch (err) {
      Log.error(err);
    }
  };
  const statusUpdate = async () => {
    try {
      let { id } = selectedSection.member_section;
      let payload = {
        member_section_id: id,
      };

      let result = await setStatusComplete({ payload, orgId });
      if (result) {
        const updatedMemberProgram = result.data.data.memberProgram;
        const { member, program } = clonedMemberProgram;
        const updateMemberProgram = { ...updatedMemberProgram, member, program };
        dispatch(storeMemberProgram(updateMemberProgram));
        let cloneActiveComponent = JSON.parse(JSON.stringify(selectedSection));
        cloneActiveComponent.member_section.status = 'Completed';
        dispatch(setActiveSection(cloneActiveComponent));
        updateStatusOnCol(cloneActiveComponent.member_section.status);
        setMarkToggle(false);
      }
    } catch (error) {
      Log.error(error);
      setMarkToggle(false);
    }
  };

  const onMarkClick = (e) => {
    setMarkToggle((toggle) => !toggle);
  };
  const displayToaster = (msg) => {
    dispatch(setToastBoxStatus(true));
    dispatch(setToastBoxDescription(msg));
  };

  const handleAddTarget = () => setAddTargetShow(true);
  const handleMltipleComponent = () => setMultipleComponentShow(true);

  const dmsToken = ls.get('dmstoken');
  const handleClose = () => {
    setLoad(false);
    onClose();
  };
  useEffect(() => {
    if (show === true && data?.id) {
      fetchSectionDetail(data?.id);
    }
    // dispatch(setActiveSection(data?.member_section));
  }, [show, data]);
  const fetchSectionDetail = async (id) => {
    // getMemberSection
    try {
      setLoading(true);
      dispatch(setSelectedProgramComponent(null));
      let result = await getMemberSection(id);
      if (result) {
        setLoading(false);
        dispatch(setActiveSection(result.data?.data));
      }
    } catch (err) {
      setLoading(false);
      Log.error(err);
    }
  };
  const addObservationEvidenceList = async (member_section_id, evidences) => {
    try {
      return await addEvidences({ member_section_ids: [member_section_id], evidences });
    } catch (error) {
      Log.error(error);
    }
  };
  const setEvidencesLocal = async (data) => {
    try {
      if (data) {
        let mySelectedSection = JSON.parse(ls.get('selectedSection'));
        let rawlist = [...data];
        let list;
        if (Array.isArray(rawlist)) {
          list = rawlist.map((item) => {
            return {
              item_id: item.id.toString(),
              name: item.i_item_name,
              url: item.download_url,
              type: item.i_document_file_type,
            };
          });
          if (list.length > 0) {
            let result = await addEvidences({ member_section_ids: [...toEvidences], evidences: list });
            if (result) {
              setMultipleComponentShow(false);
              // Update on Redux

              // Check if that section id has match with section list for which evidence is upload.
              // If no foun then no need to update evidence on selectedComponent
              if (toEvidences.includes(selectedSection.member_section.id)) {
                let evidenceClone = [...evidences, ...list];
                let selectedSectionClone = JSON.parse(JSON.stringify(selectedSection));
                selectedSectionClone.member_section.evidences = evidenceClone;
                dispatch(setActiveSection(selectedSectionClone));
              }

              setDmsStatus(false);
            }
            //return await addEvidences({ member_section_ids: [mySelectedSection?.id], evidences: list });
            // addObservationEvidenceList(selectedComponent?.member_section?._id, list);
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const submitClick = () => {
    setProgramSubmitToggle(!programSubmitToggle);
  };
  const widgetResponse = async (data) => {
    // setUploadedItem(result);
    try {
      let mySelectedSection = JSON.parse(ls.get('selectedSection'));
      if (mySelectedSection.status === 'Not Started') {
        mySelectedSection.status = 'In Progress';
      }

      // toEvidences
      let result = await setEvidencesLocal(data);
      onChange(mySelectedSection);

      /* If this component status is Not Started need to change into In Progress */
    } catch (error) {
      Log.error(error);
    }
  };
  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  async function downloadFile(myfile) {
    let url = myfile?.link ? myfile.link : myfile?.url;
    const aElement = document.createElement('a');
    aElement.setAttribute('download', myfile.name);
    aElement.href = await toDataURL(url);
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(url);
  }
  const handleShow = (item) => {
    setFileType(item?.type || item.link.slice(-3));
    setFileLink(item?.url || item?.link);
    setFileName(item?.i_item_name || item?.name || item?.title);
    setShowModalView(true);
  };
  const updateStatusOnCol = (status) => {
    if (column === 1) {
      let firstColumn = JSON.parse(JSON.stringify(firstCol));
      firstColumn[active_first].status = status;
      dispatch(setFirstCol(firstColumn));
    } else if (column === 2) {
      let secondColumn = JSON.parse(JSON.stringify(secondCol));
      secondColumn[active_second].status = status;
      dispatch(setSecondCol(secondColumn));
    }
  };
  const saveAsDraft = async () => {
    try {
      setsaveAsDraftLoder(true);
      let { observation, id } = { ...selectedSection.member_section };
      const observation_by = ls.get('userid');
      const member_section_id = id;
      const payload = { member_section_id, observation, observation_by, is_submitted: isSubmitted };
      const res = await saveObservation(payload);
      if (res) {
        let response = { ...res };
        let cloneActiveComponent = JSON.parse(JSON.stringify(selectedSection));
        let result = { ...res.data.data };

        result.member_section = result?.member_section ? result.member_section : result?.membersection;
        cloneActiveComponent.member_section.observation = result.member_section.observation;
        cloneActiveComponent.member_section.observation_at = result.member_section.observation_at;
        cloneActiveComponent.member_section.observation_by = result.member_section.observation_by;
        dispatch(setActiveSection(cloneActiveComponent));
        //syncActiveSection(cloneActiveComponent);
        setsaveAsDraftLoder(false);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(response?.data?.data?.message));
        onChange(result.member_section.status);
        updateStatusOnCol(status);
        setProgramSubmitToggle(false);
      }
    } catch (error) {
      Log.error(error);
      setProgramSubmitToggle(false);
      setsaveAsDraftLoder(false);
    }
  };
  const submitHandler = async () => {
    try {
      let { observation, id } = { ...selectedSection.member_section };

      let payload = {
        is_submitted: true,
        member_section_id: id,
        observation,
        observation_by: ls.get('userid'),
      };
      let result = await saveObservation(payload);
      if (result) {
        let resultClone = { ...result?.data?.data };
        resultClone.member_section = resultClone?.membersection && JSON.parse(JSON.stringify(resultClone?.membersection));
        let cloneActiveSection = JSON.parse(JSON.stringify(selectedSection));
        cloneActiveSection.member_section.observation = resultClone.member_section.observation;
        cloneActiveSection.member_section.observation_at = resultClone.member_section.observation_at;
        cloneActiveSection.member_section.observation_by = resultClone.member_section.observation_by;
        cloneActiveSection.member_section.status = resultClone.member_section.status;

        setProgramSubmitToggle(false);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
        dispatch(setActiveSection(cloneActiveSection));
        updateStatusOnCol(resultClone.member_section.status);
        onChange(resultClone);
      }
    } catch (error) {
      Log.error(error);
      setProgramSubmitToggle(false);
    }
  };
  const syncActiveSection = (data) => {
    let cloneSection = JSON.parse(JSON.stringify(sections));
    if (active_column === 1) {
      cloneSection[active_first] = data;
    } else if (active_column === 2) {
      cloneSection[active_first].child_sections[active_second] = data;
    }
    dispatch(storeSections(cloneSection));
  };
  const targetHandler = (targets) => {
    setAddTargetShow(false);
  };
  const evidenceHandler = (data) => {
    setToEvidences(data);
    setDmsStatus(false);
    setTimeout(() => {
      setDmsStatus(true);
    }, 200);
  };
  const removeEvidence = async (data) => {
    try {
      let result = await deleteEvidences({ member_section_id: selectedSection.member_section.id, evidence_id: data.id });

      if (result) {
        let cloneSelectedComponent = JSON.parse(JSON.stringify(selectedSection));
        let remainFiles = cloneSelectedComponent.member_section.evidences.filter((item) => item.id !== data.id);
        cloneSelectedComponent.member_section.evidences = remainFiles;
        dispatch(setActiveSection(cloneSelectedComponent));
        setConfirmBoxStatus(false);
        setEvidenceToRemove({});
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
      }
    } catch (error) {
      Log.error(error);
    }
  };
  useEffect(() => {
    if (selectedSection) {
      ls.set('selectedSection', JSON.stringify(selectedSection));
    }
  }, [selectedSection]);

  const sendRemind = async () => {
    try {
      setRemindLoading(true);
      const payload = {
        member_section: selectedSection.member_section.id,
        note,
      };
      await sendReminder({ orgId, payload });
      hideReminderBox();
    } catch (err) {
      Log.error(err);
      setErrMsg(err?.response?.data?.message ?? err?.message);
    } finally {
      setRemindLoading(false);
    }
  };

  const hideReminderBox = () => {
    setReminder(false);
    setNote('');
    setErrMsg('');
  };

  return (
    <>
      <Offcanvas
        className="offcanvas-size size-75"
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Body>
          <div className="program-list-detail-block">
            <div className="program-fixed-header">
              <Button
                variant="text-icon"
                className="p-0 back-btn"
                onClick={() => onClose()}
              >
                {blackArrowLeftIcon} Back
              </Button>

              <div className="program-button-wrap">
                {enable_document_bank && (status === 'In Progress' || status === 'Not Started' || status === 'ReSubmission') && can_manage_apprentices && !isLearner && (
                  <button
                    onClick={handleMltipleComponent}
                    type="button"
                    className="border border-primary text-primary btn btn-outline"
                  >
                    Add Evidence
                  </button>
                )}
                {(status === 'In Progress' || status === 'Not Started' || status === 'ReSubmission') && isLearner && dmsToken && (
                  <button
                    onClick={handleMltipleComponent}
                    type="button"
                    className="border border-primary text-primary btn btn-outline"
                  >
                    Add Evidence
                  </button>
                )}
                {enable_document_bank && dmsToken && dmsStatus && (
                  <DmsUploadWidget
                    showButton={false}
                    open={true}
                    buttonTitle={'Document create folder'}
                    widgetEndPoint={`${dms_base_url}/widget/document-manager?token=${dmsToken}`}
                    uploadCallback={widgetResponse}
                  />
                )}

                {can_manage_apprentices && isSubmitted === true && !isLearner && (
                  <Button
                    variant="outline"
                    className="dark"
                    onClick={onMarkClick}
                  >
                    Mark as Complete
                  </Button>
                )}

                {status === 'Completed' && <Badge className="badge">{status}</Badge>}
                {status === 'Submitted' && <Badge className="badge warning">{status}</Badge>}

                {isRemind ? (
                  <button
                    onClick={() => setReminder(true)}
                    type="button"
                    className="border border-primary text-primary btn btn-outline"
                  >
                    Remind {sendIcon}
                  </button>
                ) : null}
              </div>
            </div>
            <div className="program-list-detail-body">
              <div className="program-detial-header-wrap">
                <div className="program-detial-info">
                  <h4>{section?.title}</h4>
                  {section?.weightage !== null && (
                    <span>
                      {translate(taxonomy)('weightage')} : <strong>{section?.weightage}</strong>
                    </span>
                  )}
                </div>
                {/* <ProgramScore scoreValue="11/100" scoreText="Your Score" /> */}
              </div>
              <div className="target-wrap">
                {(activeRole.identifier === 'apprentice' || can_view_smart_target) &&
                  targets &&
                  targets.length > 0 &&
                  targets?.map((target, i) => (
                    <Badge
                      className="badge badge-round badge-border"
                      key={i}
                    >
                      {subtractionIcon}
                      {`${translate(taxonomy)('target')}  ${i + 1}:`}
                      <span>
                        {formatUTCDate(target.date)}&nbsp; ({target.progress}%)
                      </span>
                    </Badge>
                  ))}
              </div>

              {status !== 'Completed' && ((!isLearner && can_create_smart_target) || isLearner) && (
                <Button
                  onClick={handleAddTarget}
                  variant="leading-icon"
                  className="leading-icon add-target-btn"
                  type="button"
                >
                  {addIcon} Add {translate(taxonomy)('target')}
                </Button>
              )}
              <div
                className="program-list-description"
                dangerouslySetInnerHTML={{ __html: purify.sanitize(section?.description) }}
              />
              <div className="program-list-editor">
                {observation_by && observation_at && (
                  <span className="program-list-update-text">
                    Last Update:
                    <strong>
                      {formatDate(observation_at)}

                      {observation_by?.first_name && observation_by?.last_name && ' By ' + observation_by?.first_name + ' ' + observation_by?.last_name}
                    </strong>
                  </span>
                )}
              </div>

              <>
                {(!can_manage_apprentices && !isLearner) || isSubmitted === true || isCompleted === true ? (
                  <div className="observation-block observation-pointer-block observation-read-only">
                    <h5 className="small-text-title">Observation</h5>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <div dangerouslySetInnerHTML={{ __html: observation }} />
                    </Form.Group>
                  </div>
                ) : (
                  <div className="observation-block observation-pointer-block bg-transparent">
                    <h5 className="small-text-title">Observation</h5>
                    {observation ? (
                      <>
                        <TextEditor
                          onChange={observationHandler}
                          readOnly={(!can_manage_apprentices && !isLearner) || isSubmitted === true || isCompleted === true}
                          value={observation ? observation : '<p>&nbsp;</p>'}
                        />
                      </>
                    ) : (
                      <TextEditor
                        onChange={observationHandler}
                        readOnly={(!can_manage_apprentices && !isLearner) || isSubmitted === true || isCompleted === true}
                        value={'<p>&nbsp;</p>'}
                      />
                    )}
                  </div>
                )}
              </>
              {evidences && evidences.length > 0 && (
                <div className="program-list-observation">
                  <h3>Observation Evidences</h3>
                  {evidences.map((item) => (
                    <OberservationEvidence
                      link={item.url}
                      onDelete={(data) => {
                        setEvidenceToRemove(data);
                        setConfirmBoxStatus(true);
                      }}
                      title={item?.i_item_name || item.name}
                      data={item}
                      key={item._id}
                      type={item.type}
                      status={selectedSection?.member_section?.status}
                      viewBtn
                      removeBtn={isIQV || isEQV ? false : true}
                      onShowModel={() => handleShow(item)}
                      downloadBtn={() => {
                        downloadFile(item);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <div className="program-detial-footer-wrap justify-content-end">
            {(status == 'In Progress' || status == 'Not Started') && (isLearner || can_manage_apprentices) && (
              <div className="program-button-wrap">
                <Button
                  variant="outline"
                  className="dark"
                  onClick={saveAsDraft}
                >
                  {saveAsDraftLoder && (
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                  )}
                  Save as Draft
                </Button>
                <Button
                  variant="primary"
                  onClick={submitClick}
                >
                  Save &amp; Submit
                </Button>
              </div>
            )}
          </div>
        </Offcanvas.Footer>
      </Offcanvas>
      {/*  onEvidence={evidenceHandler} */}
      {member_program && selectedSection && multipleComponentShow === true && (
        <MultipleComponentOffCanvas
          onEvidence={evidenceHandler}
          memberProgramId={member_program?.id}
          memberSectionId={selectedSection?.member_section?.id}
          show={multipleComponentShow}
          onClose={() => setMultipleComponentShow(false)}
        />
      )}
      {selectedSection && (
        <MarkAsCompleteModal
          show={markToggle}
          onClose={(status) => {
            if (status && selectedSection) {
              let cloneActiveSection = { ...selectedSection };
              cloneActiveSection.member_section.status = status;
              dispatch(setActiveSection(cloneActiveSection));
            }

            setMarkToggle(false);
          }}
          onMarkComplete={(status) => {
            if (status) {
              statusUpdate();
            }
          }}
          member_section_id={selectedSection?.member_section?.id}
        />
      )}
      {
        <AddTarget
          show={addTargetShow}
          onClose={() => setAddTargetShow(false)}
          onSave={targetHandler}
          displayMsg={(msg) => displayToaster(msg)}
        />
      }
      <ViewModel
        onClose={() => setShowModalView(false)}
        show={showModalView}
        fileType={fileType}
        fileLink={fileLink}
        fileName={fileName}
      />
      {setConfirmBoxStatus && (
        <ConfirmBox
          show={confirmBoxStatus}
          question={`Are you sure you want to remove this Evidence?`}
          okText="Yes"
          cancelText="No"
          onHide={() => {
            setConfirmBoxStatus(false);
          }}
          onOk={() => removeEvidence(evidenceToRemove)}
        />
      )}
      {programSubmitToggle && (
        <SubmitModal
          loading={saveAsDraftLoder}
          onSubmit={() => submitHandler()}
          onSaveAsDraft={saveAsDraft}
          show={programSubmitToggle}
          onClose={() => setProgramSubmitToggle(false)}
        />
      )}

      <InputPopUpBox
        show={reminder}
        loading={remindLoading}
        title={'Reminder'}
        inputField={true}
        inputType={'reminder'}
        inputLabel={'Please provide note for reminder'}
        inputPlaceholder={''}
        value={note}
        disabled={false}
        onChange={(e) => setNote(e.target.value)}
        onOk={() => sendRemind()}
        okText={'Confirm'}
        onHide={() => hideReminderBox()}
        cancelText={'Cancel'}
        errMsg={errMsg}
      />
    </>
  );
}

export default memo(LectureObservationOffCanvas);
