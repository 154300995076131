import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetAlertNotification } from '../redux';
import { alertDangerIcon, alertTickIcon, alertCrossIcon, alertQuestionIcon } from 'constants/icons';

function AlertNotification() {
  const dispatch = useDispatch();

  const { msg, alertStatus, title, infoStatus } = useSelector((state) => state.alertNotification);

  useEffect(() => {
    if (msg) {
      const timer = setTimeout(
        () => {
          dispatch(resetAlertNotification());
        },
        infoStatus ? 30000 : 10000,
      );

      return () => clearTimeout(timer);
    }
  }, [msg, dispatch]);

  const icon = infoStatus ? alertQuestionIcon : alertStatus ? alertTickIcon : alertDangerIcon;

  if (!msg) {
    return '';
  }

  return (
    <div className="alert-wrapper position-fixed top-0 start-50 translate-middle-x">
      <div className={`alert-notification ${infoStatus ? 'alert-info' : alertStatus ? 'alert-success' : 'alert-danger'} preview-notify`}>
        {icon && <div className={`${!infoStatus ? 'alert-icon-wrap' : 'alert-icon-wrap'}`}>{icon}</div>}
        <div className="alert-info-wrap">
          {title && <h4>{title}</h4>}
          {msg && <p>{msg}</p>}
        </div>

        <span
          role="button"
          className="alert-close-btn"
          onClick={() => {
            dispatch(resetAlertNotification());
          }}
        >
          {alertCrossIcon}
        </span>
      </div>
    </div>
  );
}
export default AlertNotification;
