import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import Log from 'lib/Log';

function InputMCQ({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();

  const valueControl = (e) => {
    try {
      let cloneFormData = JSON.parse(JSON.stringify(formData));
      let obj = cloneFormData.find((item) => item.id === e.target.id);
      let index = cloneFormData.indexOf(obj);
      obj.value = e.target.value;
      cloneFormData[index] = obj;
      dispatch(storeFormData(cloneFormData));
    } catch (error) {
      Log.error(error);
    }
  };
  return (
    <>
      <Form.Label
        htmlFor={state.label + state.id}
        className="form-builder-label mb-0"
      >
        {state.label}
      </Form.Label>
      {state?.mcq_option?.length > 0
        ? state.mcq_option.map((item, index) => {
            return (
              <div
                className="d-flex flex-col mb-1 ms-2"
                key={index}
              >
                <Form.Check
                  name={state?.id}
                  id={state?.id}
                  value={item.label}
                  label={item.label}
                  type="radio"
                  className="align-items-center pt-3 pe-1"
                  disabled={mode === 'preview' || mode === 'readonly' ? 'disabled' : ''}
                  onChange={valueControl}
                  checked={state?.value === item.label}
                />
              </div>
            );
          })
        : ''}
    </>
  );
}
export default memo(InputMCQ);
