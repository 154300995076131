import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFeedbacks, setFirstCol, setSelectedProgramComponent, setToastBoxDescription, setToastBoxStatus } from '../../../redux';
import AddFeedbackOffCanvas from '../program/AddFeedbackOffCanvas';
import Card from 'react-myoneui/lib/Card';
import FeedBackBlock from '../../../components/learners/FeedBackBlock';
import { addIcon } from '../../../constants/icons';
import { useLocation } from 'react-router-dom';
import ls from 'localstorage-slim';
import Log from '../../../lib/Log';
// enable global encryption
ls.config.encrypt = true;
export const Feedback = ({ onUpdateSections = () => {}, onUpdateSectionOnly = () => {}, onSubmit = () => {} }) => {
  const selectedComponent = useSelector((state) => (state?.sections?.active_component ? state?.sections?.active_component : null));
  const status = useSelector((state) => (state?.sections?.active_component?.status ? state?.sections?.active_component?.status : state?.sections?.active_component?.member_section?.status));
  const dispatch = useDispatch();
  const [addFeedbackShow, setAddFeedbackShow] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const role = ls.get('role');
  const { pathname } = useLocation();
  const activeRole = JSON.parse(ls.get('activeRole'));
  const pathRole = pathname.split('/')[1];
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathRole === 'eqv' && activeRole.identifier === 'eqv';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';

  const handleAddFeedback = () => setAddFeedbackShow(true);
  const feedbacks = useSelector((state) => state?.feedbacks?.data);
  const enableFeedbackToLearner = activeRole.privileges.enable_feedback_to_learner;
  const feedbackList = selectedComponent?.member_section?.feedbacks ? selectedComponent.member_section.feedbacks : selectedComponent?.feedbacks;
  const updateFeedbackCounter = (data) => {
    try {
      let component_type = selectedComponent?.member_section?.section?.section_type;
      let cloneFeedbacks = JSON.parse(JSON.stringify(feedbacks));
      if (component_type === 'UnitComponent') {
        cloneFeedbacks.totalNewUnitFeedbacks += 1;
        cloneFeedbacks.totalUnitfeedbacks += 1;
      } else if (component_type === 'CourseComponent') {
        cloneFeedbacks.totalNewCourseFeedbacks += 1;
        cloneFeedbacks.totalCoursefeedbacks += 1;
      } else if (component_type === 'ResourceComponent') {
        cloneFeedbacks.totalNewResourceFeedbacks += 1;
        cloneFeedbacks.totalResourcefeedbacks += 1;
      } else if (component_type === 'OTJComponent') {
        cloneFeedbacks.totalNewOTJFeedbacks += 1;
        cloneFeedbacks.totalOTJfeedbacks += 1;
      }
      cloneFeedbacks.totalProgramfeedbacks = cloneFeedbacks.totalProgramfeedbacks + 1;
      dispatch(setFeedbacks(cloneFeedbacks));
    } catch (error) {
      Log.error(error);
    }
  };

  const feedbackSaveHandler = (data) => {
    try {
      Log.info(data);
      Log.info(data.member_section_status);
      setAddFeedbackShow(false);

      let cloneSelectedComponent = JSON.parse(JSON.stringify(selectedComponent));

      if (cloneSelectedComponent.member_section) {
        cloneSelectedComponent.member_section?.feedbacks.unshift(...data.data);
        cloneSelectedComponent.member_section['last_assessed_at'] = data?.data?.[0]?.last_assessed_by_learner;
        cloneSelectedComponent.member_section['status'] = data.member_section_status;
        Log.info(cloneSelectedComponent);
      } else {
        cloneSelectedComponent?.feedbacks.unshift(...data.data);
        cloneSelectedComponent['last_assessed_at'] = data?.data?.[0]?.last_assessed_by_learner;
        cloneSelectedComponent.status = data?.member_section_status;
        Log.info(cloneSelectedComponent);
      }

      onUpdateSectionOnly(cloneSelectedComponent);
      dispatch(setSelectedProgramComponent(cloneSelectedComponent));
      updateFeedbackCounter(data);
      dispatch(setToastBoxDescription(data.message));
      dispatch(setToastBoxStatus(true));
    } catch (error) {
      Log.error(error);
    }
  };

  const showEqvFeedback = [isEQV, isIQV, isAdmin].filter(Boolean).length !== 0;

  const filteredFeedbacks = () => {
    if (isLearner) {
      const filteredFeedback = feedbackList?.filter((feedback) => {
        if (feedback.created_by?.role?.identifier === 'iqv') {
          return feedback.created_to?.role?.identifier === 'assessor' ? false : true;
        }

        if (feedback.created_by?.role?.identifier === 'eqv') {
          return showEqvFeedback ? true : false;
        }
        return feedback;
      });

      return filteredFeedback;
    }

    return feedbackList?.filter((feedback) => {
      if (feedback.created_by?.role?.identifier === 'eqv') {
        return showEqvFeedback ? true : false;
      }
      return feedback;
    });
  };

  const filteredFeedbackCount = showMore ? filteredFeedbacks()?.slice(0, 5) : filteredFeedbacks();

  return (
    <>
      <AddFeedbackOffCanvas
        onSave={feedbackSaveHandler}
        onSubmit={() => onSubmit()}
        show={addFeedbackShow}
        onClose={() => setAddFeedbackShow(false)}
        isIQV={isIQV}
        isEQV={isEQV}
      />

      <div className="feedback-block-wrapper">
        <div className="add-observation-wrap ms-5">
          {!isLearner && enableFeedbackToLearner && (
            <Card.Link
              href="#"
              onClick={handleAddFeedback}
            >
              {addIcon}
              Add Feedback
            </Card.Link>
          )}
          {isLearner && status === 'ReSubmission' && (
            <Card.Link
              href="#"
              onClick={handleAddFeedback}
            >
              {addIcon}
              Resubmit Observation
            </Card.Link>
          )}
        </div>
        {feedbackList && feedbackList.length > 0 && (
          <div className="feedback-holder">
            {filteredFeedbackCount?.map((item) => (
              <FeedBackBlock
                key={item.id}
                data={item}
              />
            ))}

            {filteredFeedbacks()?.length > 5 && showMore && (
              <div className="see-more p-2">
                <button
                  className="load-more-btn"
                  onClick={() => setShowMore(false)}
                >
                  See All
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
