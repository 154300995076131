import Log from 'lib/Log';
import DropdownCollection from 'components/DropdownCollection';
import ls from 'localstorage-slim';
import { sectionMenuIcon, wifiIcon, leftArrow, imageIcon, singleLineIcon, multipleLineIcon } from 'constants/icons';
// Icons
import { useEffect, useState, memo } from 'react';
import { getRoles } from 'api/roles';
import { useDispatch, useSelector } from 'react-redux';
import EditSection from './EditSection';
import { setMode } from '../../../redux/FormBuilderSlice';
import EditSubSection from './EditSubSection';
import EditElement from './EditElement';
import EditEvidenceElement from './EditEvidenceElement';
import { storeRoles } from '../../../redux';
import EditDropdownElement from './EditDropdownElement';
import EditMCQElement from './EditMCQElement';
import EditBehaviourBlockType1 from './EditBehaviourBlockType1';

const singleLine = [{ id: 'text', name: 'Single line input text field' }];

// const fieldAccessOptions = [{ id: 1, name: 'All Access' }];

function FormBuilderEditOption(props) {
  const dispatch = useDispatch();
  const orgId = ls.get('orgId');
  const [state, setState] = useState({});
  const elements = useSelector((state) => state.formbuilder.elements);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [rolesList, setRolesList] = useState([]);
  const [displayRoles, setDisplayRoles] = useState([]);
  const storedRoles = useSelector((state) => state.roles.list);
  const {
    privileges: { can_manage_apprentices },
  } = JSON.parse(ls.get('activeRole'));

  const fetchRoles = async () => {
    try {
      const roles = await getRoles(orgId);
      setRolesList(roles?.data?.data.map((role) => ({ id: role.id, name: role.name })));
      // const allowedIdentifiers = ['admin', 'apprentice', 'assessor', 'tutor'];
      // const list = roles?.data?.data.filter((role) => allowedIdentifiers.includes(role.identifier));
      // const finalList = list.map((role) => role.name);
      // // setDisplayRoles((roles?.data?.data.map((list) => list.name)));
      // setDisplayRoles(finalList);
      dispatch(storeRoles(roles.data));
    } catch (err) {
      Log.error('Error:EditUserForm/fetchRoles', err);
    }
  };

  useEffect(() => {
    if (storedRoles.length < 1) {
      fetchRoles();
    } else {
      setRolesList(storedRoles);
      // let nameOnlyList = storedRoles.map((role) => role.name);
      // const allowedIdentifiers = ['admin', 'apprentice', 'assessor', 'tutor'];
      // const list = storedRoles.filter((role) => allowedIdentifiers.includes(role.identifier));
      // const finalList = list.map((role) => role.name);
      // // setDisplayRoles(nameOnlyList);
      // setDisplayRoles(finalList);
    }
  }, []);

  useEffect(() => {
    setState(activeEle);
  }, [activeEle]);

  useEffect(() => {
    if (can_manage_apprentices) {
      const allowedIdentifiers = ['admin', 'apprentice', 'assessor', 'tutor'];
      const list = storedRoles.filter((role) => allowedIdentifiers.includes(role.identifier));
      const finalList = list.map((role) => role.name);
      setDisplayRoles(finalList);
    } else {
      const allowedIdentifiers = ['apprentice', 'assessor', 'tutor'];
      const list = storedRoles.filter((role) => allowedIdentifiers.includes(role.identifier));
      const finalList = list.map((role) => role.name);
      setDisplayRoles(finalList);
    }
  }, [storedRoles, can_manage_apprentices]);

  const goBack = () => {
    dispatch(setMode('view'));
  };
  const showFormElementIcon = (field_type) => {
    switch (field_type) {
      case 'text':
        return singleLineIcon;
      case 'textarea':
        return multipleLineIcon;
      case 'image':
        return imageIcon;
      case 'evidence':
        return imageIcon;
      default:
        return singleLineIcon;
    }
  };
  const showIcon = (obj) => {
    switch (obj.name) {
      case 'section':
        return sectionMenuIcon;

      case 'subSection':
        return wifiIcon;

      case 'form-element':
        return showFormElementIcon(obj.field_type);

      default:
        return sectionMenuIcon;
    }
  };
  const showEdit = () => {
    switch (state.field_type) {
      case 'text':
        return (
          <EditElement
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      case 'textarea':
        return (
          <EditElement
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      case 'image':
        return (
          <EditElement
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      case 'evidence':
        return (
          <EditEvidenceElement
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      case 'dropdown':
        return (
          <EditDropdownElement
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      case 'single_select_mcq':
        return (
          <EditMCQElement
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      case 'behavior_block_type1':
        return (
          <EditBehaviourBlockType1
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      case 'behavior_block_type2':
        return (
          <EditBehaviourBlockType1
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
      default:
        return (
          <EditElement
            rolesList={rolesList}
            displayRoles={displayRoles}
          />
        );
    }
  };
  return (
    <>
      <div className="form-builder-fixed-header form-builder-border-bottom">
        <div className="d-flex gap-2">
          <span
            className="card-price-tag"
            onClick={goBack}
          >
            {leftArrow}
          </span>

          <div className="single-line-dropdown">
            <div className="form-builder-elements-icon">{showIcon({ name: state?.name, type: state?.type })}</div>
            <div className="info">
              <h4>{state.title}</h4>
              <span>{state?.description}</span>
            </div>
          </div>
        </div>
      </div>
      {state.name === 'section' ? <EditSection /> : state.name === 'sub-section' ? <EditSubSection /> : state.name === 'form-element' ? showEdit() : ''}
    </>
  );
}
export default memo(FormBuilderEditOption);
