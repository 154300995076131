import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveEle, setElements, setMode, updateElement } from '../../../redux/FormBuilderSlice';
import { Form, FloatingLabel, Button, Spinner } from 'react-myoneui';
import { editFormField } from 'api/formBuilder';
import DropdownCheckboxMultiSelect from 'components/DropdownCheckboxMultiSelect';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
ls.config.encrypt = true;

function EditElement({ data = {}, onSave, rolesList, displayRoles }) {
  const [state, setState] = useState({});
  const dispatch = useDispatch(useDispatch);
  const elements = useSelector((state) => state.formbuilder.elements);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const orgId = ls.get('orgId');

  const editHandler = (e) => {
    let cloneState = JSON.parse(JSON.stringify(state));
    cloneState[e.target.name] = e.target.value;
    setState(cloneState);
  };

  const onPeriodItemsChange = (item) => {
    try {
      setRoles((roles) => {
        const prevItems = [...roles];
        const index = prevItems.indexOf(item);
        if (index === -1) {
          return [...prevItems, item];
        } else {
          prevItems.splice(index, 1);
          return prevItems;
        }
      });
    } catch (err) {
      Log.error(err);
    }
  };

  useEffect(() => {
    if (roles.length > 0) {
      setState({ ...state, editable_by: rolesList.filter((item) => roles.includes(item.name)).map((item) => item.id) });
    }
  }, [roles]);

  const saveHander = async () => {
    let cloneElements = [...elements];
    let element = cloneElements.find((item) => item.id === state.id);
    let index = cloneElements.indexOf(element);
    cloneElements[index] = { ...state };
    setLoading('loading');
    await editFormField({ ...state });
    dispatch(setActiveEle({ ...state }));
    // dispatch(updateElement({ ...state }));
    // dispatch(setElements(cloneElements));
    dispatch(setMode('view'));
  };
  useEffect(() => {
    setState(activeEle);
    setRoles(rolesList.filter((item) => activeEle?.editable_by.includes(item.id)).map((item) => item.name));
  }, [activeEle, rolesList]);

  return (
    <div className="form-builder-edit-option">
      <FloatingLabel
        controlId="labelId"
        label="Label *"
        className="mb-28"
      >
        <Form.Control
          type="text"
          value={state.label}
          onChange={editHandler}
          name="label"
        />
      </FloatingLabel>
      <hr />

      <FloatingLabel
        controlId="labelId"
        label="Profile Image Helper Text"
        className="mb-28"
      >
        <Form.Control
          type="text"
          value={state.profile_img_helper_text}
          onChange={editHandler}
          name="profile_img_helper_text"
        />
      </FloatingLabel>
      <FloatingLabel
        controlId="labelId"
        label="Description Placeholder"
        className="mb-28"
      >
        <Form.Control
          type="text"
          value={state.placeholder}
          onChange={editHandler}
          name="placeholder"
        />
      </FloatingLabel>
      <DropdownCheckboxMultiSelect
        className="mb-4 user-max-content-dropdown"
        label="Editable By"
        name="editable_by"
        autoComplete="off"
        items={displayRoles}
        selectedItems={roles}
        onChange={onPeriodItemsChange}
      />
      <div className="button-wrap">
        <Button
          variant="secondary"
          onClick={saveHander}
          disabled={roles?.length < 1}
        >
          {loading === 'loading' && (
            <Spinner
              animation="border"
              size="sm"
            />
          )}
          Save
        </Button>
      </div>
    </div>
  );
}
export default memo(EditElement);
