import { useState, memo, useEffect } from 'react';
import FormElement from './FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveEle, deleteElement, setMode, setElements, addElement, addElements, setIsDraged } from '../../redux/FormBuilderSlice';
import { FloatingLabel, Form, Row, Col, Spinner } from 'react-myoneui';
import DropElementsSection from './DropElementsSection';
import DropELementsDot from './DropELementsDot';
import DropElementsDropdown from './DropElementsDropdown';
import { removeSubSection, createFormField, getFormElements, createFormSubSection, cloneFormSubSection } from 'api/formBuilder';
import { blackArrowDownIcon } from 'constants/icons';
import { sortElements } from 'lib/common';
import Log from 'lib/Log';
import { insertSubSection } from 'api/formBuilder';
import { getRoles } from 'api/roles';
import ls from 'localstorage-slim';
import { storeRoles } from '../../redux';

function SubSection({ dragItem = {}, id = '', data, onDrop, show = true, parentId, onDelete, onLoadSections, onDragItem, onEdit, onSubSectionDrop }) {
  const [elements, setElements] = useState([]);
  // const data = elements.find((item) => item.id === id);
  const mode = useSelector((state) => state.formbuilder.mode);
  const [state, setState] = useState({ ...data });
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  // const childs = elements.filter((item) => item.parent_id === id);
  const [dropOverStatus, setDropOverStatus] = useState(false);
  const [dragOverId, setDragOverId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showChild, setShowChild] = useState(true);
  const dispatch = useDispatch();
  const storedRoles = useSelector((state) => state.roles.list);
  const orgId = ls.get('orgId');
  const [filteredRoleList, setFilteredRoleList] = useState([]);
  const {
    privileges: { can_manage_apprentices },
  } = JSON.parse(ls.get('activeRole'));

  // code for role filter

  const fetchRoles = async () => {
    try {
      const roles = await getRoles(orgId);
      dispatch(storeRoles(roles?.data));
    } catch (err) {
      Log.error('Error:EditUserForm/fetchRoles', err);
    }
  };

  useEffect(() => {
    if (can_manage_apprentices) {
      const allowedIdentifiers = ['admin', 'apprentice', 'assessor', 'tutor'];
      const filteredRoles = storedRoles.filter((role) => allowedIdentifiers.includes(role.identifier));
      const rolesId = filteredRoles.map((role) => role.id);
      setFilteredRoleList(rolesId);
    } else {
      const allowedIdentifiers = ['apprentice', 'assessor', 'tutor'];
      const filteredRoles = storedRoles.filter((role) => allowedIdentifiers.includes(role.identifier));
      const rolesId = filteredRoles.map((role) => role.id);
      setFilteredRoleList(rolesId);
    }
  }, [storedRoles.length, can_manage_apprentices]);

  useEffect(() => {
    if (storedRoles.length < 1) {
      fetchRoles();
    }
  }, []);

  const dragStart = (e) => {
    // setDragStartStatus(true);
    // console.log('Drag Start', JSON.parse(e.target.dataset.item));
    // setDragBox(JSON.parse(e.target.dataset.item));
    e.dataTransfer.setData('text', e.target.dataset.item);
    onDragItem(JSON.parse(e.target.dataset.item));
    dispatch(setIsDraged(true));
  };
  const dragOver = (e) => {
    try {
      if ((dragItem?.name == 'form-element' || dragItem?.name == 'sub-section') && id !== dragItem.id) {
        e.stopPropagation();
        setDropOverStatus(true);
        setDragOverId(e.target.dataset.id);
        e.dataTransfer.setData('element', dragItem);
        e.preventDefault();
      }
    } catch (error) {
      Log.error(error);
    }
    // onDragOver();
  };
  const dragEnd = (e) => {
    setDropOverStatus(false);
    e.preventDefault();
  };
  const dropSubSection = async (e) => {
    let fieldData = JSON.parse(e.dataTransfer.getData('text'));
    let { heading, sub_heading, parent_id, name, title } = { ...fieldData };
    let payload = { heading, sub_heading, parent_id, name, title, parent_id: parentId, index: parseInt(e.currentTarget.dataset.index) };
    setLoading('loading');
    let result = await createFormSubSection(payload);
    setLoading('loaded');
    onSubSectionDrop(result.data.data);

    e.preventDefault();
  };
  const drop = async (e) => {
    try {
      if ((dragItem?.name == 'form-element' || dragItem?.name == 'sub-section') && id !== dragItem.id) {
        let fieldData = JSON.parse(e.dataTransfer.getData('text'));
        if (fieldData.name === 'sub-section') {
          if (dragItem.index) {
            let payload = {
              parent_id: dragItem.parent_id,
              sub_section_id: dragItem.id,
              destination_index: parseInt(e.currentTarget.dataset.index),
            };
            setLoading('loading');
            await insertSubSection(payload);
            setLoading('loaded');
            onLoadSections();
          } else {
            dropSubSection(e);
          }
          // dropSubSection(e);
          dispatch(setIsDraged(false));
        } else if (fieldData.name === 'form-element') {
          let payload = { ...fieldData, parent_id: state.id, editable_by: filteredRoleList };
          setLoading('loading');
          let result = await createFormField(payload);
          setLoading('loaded');
          setElements([...elements, { ...result.data.data }]);
          dispatch(setActiveEle(result.data.data));
          dispatch(setMode('edit'));
          setDropOverStatus(false);
          dispatch(setIsDraged(false));
          fetchFormElements();
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const dragLeave = (e) => {
    if (dragItem?.name == 'form-element' || dragItem?.name == 'sub-section') {
      setDropOverStatus(false);
    }
  };
  const editHandler = () => {
    dispatch(setActiveEle({ ...state }));
    dispatch(setMode('edit'));
  };
  const deleteHandler = async () => {
    try {
      await removeSubSection({ sub_section_id: id });
      let mySubSection = elements.filter((item) => item.id !== id);

      setElements([]);
      onDelete(state);
      dispatch(setMode('view'));
    } catch (error) {
      Log.error(error);
    }
  };

  // duplicate form sub-section start here
  const duplicateSubSection = async () => {
    try {
      const payload = { parent_id: parentId, sub_section_id: id };
      setLoading('loading');
      const response = await cloneFormSubSection(payload);
      if (response?.data?.message === 'form-sub-section-duplicate-success') {
        setLoading('loaded');
        // setElements([...elements, { ...response.data.data }]);
        onLoadSections();
      }
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };

  const makeActive = (e) => {
    try {
      dispatch(setMode('active'));
      dispatch(setActiveEle({ ...state }));
      e.preventDefault();
    } catch (error) {
      Log.error(error);
    }
  };
  const editElement = (data) => {
    let cloneElements = [...elements];
    let obj = cloneElements.find((item) => item.id === data.id);
    let index = cloneElements.indexOf(obj);
    cloneElements[index] = { ...data };
    setElements(cloneElements);
  };
  const fetchFormElements = async () => {
    try {
      setLoading('loading');
      let result = await getFormElements({ subSectionId: id });
      setLoading(true);
      setElements(sortElements([...result.data.data]));
    } catch (error) {
      setLoading(true);
      Log.error(error);
    }
  };
  const adjustElementDrop = (payload) => {
    var foundElement = false;
    var colls = [];
    elements.forEach((item) => {
      var myelement = { ...item };
      if (foundElement) {
        myelement.index = myelement.index + 1;
        colls.push(myelement);
      } else if (item.index === payload.index) {
        let newElement = { ...payload };

        myelement.index = myelement.index + 1;
        colls = [...colls, newElement, myelement];
        foundElement = true;
      } else {
        colls.push(myelement);
      }
    });

    setElements(colls);
    dispatch(setMode('edit'));
    dispatch(setActiveEle(payload));
  };
  const dropElement = (data) => {
    adjustElementDrop(data);
  };
  useEffect(() => {
    setState(data);
    fetchFormElements();
  }, [data]);
  useEffect(() => {
    if (activeEle?.id === state?.id) {
      // setState(activeEle);
      onEdit(activeEle);
    }
  }, [activeEle]);

  const deleteElement = (data) => {
    setElements([...elements].filter((item) => item.id !== data.id));
  };
  return (
    <>
      <div className={` ${dropOverStatus ? 'dropOver' : ''}`}>
        {mode === 'active' && activeEle.id === state.id ? (
          <Row className={`form-collapse-wrap subSectionBlock ${show ? '' : 'd-none'}`}>
            <Col className="py-2">
              <DropElementsSection
                onClickOutside={() => dispatch(setMode('view'))}
                className="drop-elements-primary py-2 bg-transparent"
              >
                <DropELementsDot />
                <div className="form-builder-sub-section">
                  <h4>{state?.heading}</h4>
                  <span className="desc"> {state?.sub_heading}</span>
                </div>
                <DropElementsDropdown
                  onEdit={editHandler}
                  onDelete={deleteHandler}
                  onDuplicate={duplicateSubSection}
                  title={state.title}
                />
              </DropElementsSection>
            </Col>
            <button
              className={`form-collapse-btn  btn btn-icon rounded-circle ${showChild ? 'active' : ''}`}
              onClick={() => setShowChild(!showChild)}
            >
              {blackArrowDownIcon}
            </button>
          </Row>
        ) : (
          <Row
            className={`form-collapse-wrap ps-2 ${show ? '' : 'd-none'}`}
            name="subSection"
            data-id={id}
            data-index={state?.index}
            data-item={JSON.stringify(state)}
            onDragOver={dragOver}
            onDragLeave={dragLeave}
            onDrop={drop}
            draggable={true}
            onDragStart={dragStart}
          >
            <Col
              className="py-2"
              onClick={makeActive}
            >
              <div className="form-builder-sub-section">
                <h4>{state?.heading}</h4>
                <span className="desc"> {state?.sub_heading}</span>
              </div>
            </Col>
            <button
              className={`form-collapse-btn  btn btn-icon rounded-circle ${showChild ? 'active' : ''}`}
              onClick={() => setShowChild(!showChild)}
            >
              {blackArrowDownIcon}
            </button>
          </Row>
        )}
        {loading === 'loading' ? (
          <Spinner animation="border" />
        ) : (
          <Row className={`${show && showChild ? '' : 'd-none'}`}>
            {elements &&
              elements.length > 0 &&
              elements.map((item) => (
                <FormElement
                  key={item.id}
                  id={item.id}
                  data={item}
                  onDelete={deleteElement}
                  onEdit={editElement}
                  onDropElement={dropElement}
                  parentId={state.id}
                  loader={loading}
                  dragItem={dragItem}
                  onDragItem={onDragItem}
                  onLoadFields={fetchFormElements}
                />
              ))}
          </Row>
        )}
      </div>
    </>
  );
}
export default memo(SubSection);
