import { memo, useEffect, useState } from 'react';
import InputText from './InputText';
import InputTextarea from './InputTextarea';
import InputDropdown from './InputDropdown';
import InputMCQ from './InputMCQ';
import InputBehaviourBlockType1 from './InputBehaviourBlockType1';
import Log from 'lib/Log';
import { setActiveEle, setMode, setIsDraged } from '../../redux/FormBuilderSlice';
import InputEvidence from './InputEvidence';
import { Row, Spinner } from 'react-myoneui';
import { createFormField, removeFormField, cloneFormField } from 'api/formBuilder';
import DropElementsSection from './DropElementsSection';
import DropELementsDot from './DropELementsDot';
import DropElementsDropdown from './DropElementsDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { insertFormField } from 'api/formBuilder';
function FormElement({ id = '', data, onDelete, loader = false, onEdit, onDropElement, dragItem, onDragItem, parentId, onLoadFields }) {
  const [state, setState] = useState({ ...data });
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.formbuilder.mode);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [loading, setLoading] = useState(loader);
  const [dropOverStatus, setDropOverStatus] = useState(false);
  const [showSideBox, setShowSideBox] = useState(false);
  const editHandler = () => {
    dispatch(setActiveEle({ ...state }));
    dispatch(setMode('edit'));
  };
  const dragStart = (e) => {
    e.dataTransfer.setData('text', e.target.dataset.item);
    onDragItem(JSON.parse(e.target.dataset.item));
    dispatch(setIsDraged(true));
  };
  const deleteHandler = async () => {
    setLoading('loading');
    await removeFormField({ field_id: id });
    setLoading('loaded');
    onDelete({ ...state });
  };
  // duplicate form sub-section start here
  const duplicateFormField = async () => {
    try {
      const payload = { parent_id: parentId, field_id: id };
      setLoading('loading');
      const response = await cloneFormField(payload);
      if (response?.data?.message === 'form-field-duplicate-success') {
        setLoading('loaded');
        // setElements([...elements, { ...response.data.data }]);
        onLoadFields();
      }
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };

  const dragOver = (e) => {
    try {
      if (dragItem.name === 'form-element' && id !== dragItem.id) {
        setDropOverStatus(true);
        setShowSideBox(true);
      }
      e.preventDefault();
    } catch (e) {
      Log.error(e);
    }
  };
  const dragLeave = (e) => {
    if (dragItem?.name == 'form-element') {
      setDropOverStatus(false);
    }
  };
  const drop = async (e) => {
    try {
      e.preventDefault();
      if (dragItem.name === 'form-element' && id !== dragItem.id) {
        if (dragItem.index) {
          let payload = {
            destination_index: parseInt(e.currentTarget.dataset.index),
            field_id: dragItem.id,
            parent_id: parentId,
          };
          setLoading('loading');
          await insertFormField(payload);
          setLoading('loaded');
          onLoadFields();
        } else {
          let fieldData = JSON.parse(e.dataTransfer.getData('text'));
          let payload = { ...fieldData, parent_id: parentId, index: parseInt(e.currentTarget.dataset.index) };
          setLoading('loading');
          let result = await createFormField(payload);
          setLoading('loaded');
          onDropElement(result?.data?.data);
          setDropOverStatus(false);
        }
        dispatch(setIsDraged(false));
      }
    } catch (err) {
      Log.error(err);
    }
  };
  useState(() => {
    setState(data);
  }, [data]);

  const makeActive = (e) => {
    dispatch(setMode('active'));
    dispatch(setActiveEle({ ...state }));
  };
  const displayElement = () => {
    return state?.field_type === 'text' ? (
      <InputText
        className="element"
        data={state}
      />
    ) : state?.field_type === 'textarea' ? (
      <InputTextarea
        className="element"
        data={state}
      />
    ) : state?.field_type === 'evidence' ? (
      <InputEvidence
        className="element"
        id={id}
        data={state}
      />
    ) : state?.field_type === 'dropdown' ? (
      <InputDropdown
        className="element"
        data={state}
      />
    ) : state?.field_type === 'single_select_mcq' ? (
      <InputMCQ
        className="element"
        data={state}
      />
    ) : state?.field_type === 'behavior_block_type1' || state?.field_type === 'behavior_block_type2' ? (
      <InputBehaviourBlockType1
        className="element"
        data={state}
      />
    ) : (
      <InputText
        className="element"
        data={state}
      />
    );
  };
  useEffect(() => {
    if (activeEle.id === state.id) {
      setState({ ...activeEle });
    }
  }, [activeEle]);
  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  return (
    <>
      {mode === 'active' && activeEle.id === state.id ? (
        <DropElementsSection
          onClickOutside={() => dispatch(setMode('view'))}
          className={`drop-elements-primary py-2 bg-transparent col col-md-${state.col_width}`}
        >
          <DropELementsDot />
          {displayElement()}
          <DropElementsDropdown
            onEdit={editHandler}
            onDelete={deleteHandler}
            onDuplicate={duplicateFormField}
            title={state.title}
          />
        </DropElementsSection>
      ) : (
        <>
          <div
            onClick={makeActive}
            data-id={state.id}
            data-index={state?.index}
            data-item={JSON.stringify({ ...state })}
            onDragOver={dragOver}
            onDrop={drop}
            className={`col ps-3 py-2 col-md-${state.col_width} ${dropOverStatus ? 'dropOverElement' : ''}`}
            onDragStart={dragStart}
            draggable={true}
            onDragLeave={dragLeave}
          >
            {loading === 'loading' ? (
              <Spinner
                animation="border"
                className="spinner-border-sm"
              />
            ) : (
              displayElement()
            )}
          </div>
          {/* {showSideBox && (
            <div
              onDragOver={dragOver}
              onDragLeave={dragLeave}
              className={`col-md-3  ${dropOverStatus ? 'dropOverElement' : 'bg-success'}`}
            ></div>
          )} */}
        </>
      )}
    </>
  );
}
export default FormElement;
