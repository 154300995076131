import { memo, useEffect, useState } from 'react';

import { Row, Col } from 'react-myoneui';
import InputText from './InputText';
import InputTextarea from './InputTextarea';
import InputEvidence from './InputEvidence';
import InputDropdown from './InputDropdown';
import InputBehaviourBlockType1 from './InputBehaviourBlockType1';
import InputMCQ from './InputMCQ';
import { useDispatch, useSelector } from 'react-redux';
import { storeRoles } from '../../../redux';
import { getRoles } from 'api/roles';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
// enable global encryption
ls.config.encrypt = true;

function SubSection({ data, mode, status, payloadData, form_section }) {
  const allData = useSelector((state) => state.formData?.data);
  const elements = allData.filter((item) => item.parent_id === data.id);
  const orgId = ls.get('orgId');

  // to check whether the form component is editable for only accessible role
  // const activeRole = ls.get('role');
  const activeRole = JSON.parse(ls.get('activeRole')).id;
  const [rolesList, setRolesList] = useState([]);
  const storedRoles = useSelector((state) => state.roles.list);
  const dispatch = useDispatch();

  const fetchRoles = async () => {
    try {
      const roles = await getRoles(orgId);
      setRolesList(roles?.data?.data.map((role) => ({ id: role.id, name: role.name })));
      dispatch(storeRoles(roles.data));
    } catch (err) {
      Log.error('Error:EditUserForm/fetchRoles', err);
    }
  };

  // filtering editable access
  const filterEditAccess = (roleArray) => {
    if (roleArray?.length === 0) {
      return false; // Return false if array is empty
    } else {
      // return array of editable role name
      const editableRolesArray = roleArray
        .map((roleId) => {
          const role = rolesList.find((role) => role.id === roleId);
          return role ? role.id : null;
        })
        .filter((id) => id !== null);

      // return true if editable array exists active role
      return editableRolesArray.includes(activeRole);
    }
  };

  const modeStatus = (roleArray) => {
    if (roleArray?.length >= 0 && filterEditAccess(roleArray) === false) {
      return 'preview';
    } else {
      return mode;
    }
  };

  useEffect(() => {
    if (storedRoles.length < 1) {
      fetchRoles();
    } else {
      setRolesList(storedRoles);
    }
  }, []);

  return (
    <>
      <div className="subSectionBlock pb-2">
        <Row name="subSection">
          <Col className="py-2">
            <div className="form-builder-sub-section">
              <h4>{data?.heading}</h4>
              <span className="desc"> {data?.sub_heading}</span>
            </div>
          </Col>
        </Row>

        <Row>
          {elements &&
            elements.length > 0 &&
            elements.map((item) => {
              switch (item.field_type) {
                case 'text':
                  return (
                    <InputText
                      key={item.id}
                      data={item}
                      mode={modeStatus(item?.editable_by)}
                    />
                  );
                case 'textarea':
                  return (
                    <InputTextarea
                      key={item.id}
                      data={item}
                      mode={modeStatus(item?.editable_by)}
                    />
                  );
                case 'evidence':
                  return (
                    <InputEvidence
                      data={item}
                      key={item.id}
                      mode={modeStatus(item?.editable_by)}
                      payloadData={payloadData}
                      form_section={form_section}
                      status={status}
                    />
                  );
                case 'dropdown':
                  return (
                    <InputDropdown
                      data={item}
                      key={item.id}
                      mode={modeStatus(item?.editable_by)}
                      // payloadData={payloadData}
                      // form_section={form_section}
                      // status={status}
                    />
                  );
                case 'single_select_mcq':
                  return (
                    <InputMCQ
                      data={item}
                      key={item.id}
                      mode={modeStatus(item?.editable_by)}
                    />
                  );
                case 'behavior_block_type1':
                  return (
                    <InputBehaviourBlockType1
                      data={item}
                      key={item.id}
                      mode={modeStatus(item?.editable_by)}
                    />
                  );
                case 'behavior_block_type2':
                  return (
                    <InputBehaviourBlockType1
                      data={item}
                      key={item.id}
                      mode={modeStatus(item?.editable_by)}
                    />
                  );
                default:
                  return (
                    <InputText
                      key={item.id}
                      data={item}
                      mode={modeStatus(item?.editable_by)}
                      status={status}
                    />
                  );
              }
            })}
        </Row>
        {/* data.subsection && data.subsection.length > 0 && data.subsection.map((subsection) => <div>Sub Section</div>) */}
      </div>
    </>
  );
}
export default memo(SubSection);
