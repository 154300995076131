import { React, useEffect, useState } from 'react';
import { forwardRef, Children } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { reviewNewIcon, assignmentDarkIcon, coursePlayIcon, resourceIcon, formBuilderIcon, offthejobIcon, workflowIcon, eventIcon, quizNewIcon, unitBankIcon } from '../../../constants/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from '../../../lib';
import { LANG } from '../../../constants/config';
import { getTaxonomy } from '../../../api';
import { useSelector } from 'react-redux';
import ls from 'localstorage-slim';
import Log from '../../../lib/Log';
// enable global encryption
ls.config.encrypt = true;
// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});
function AddDropdownList(props) {
  const { onAddSectionStatus, id, sectionStatus, parentId, sectionType, isMandatory } = props;
  const { programId, orgId } = useParams();

  const organisationId = orgId || ls.get('orgId');
  const language = ls.get('lang') || LANG;
  const lang = useSelector((state) => state.taxonomy.data);

  var navigate = useNavigate();
  const navigateCourse = (url) => {
    navigate(url, { state: { isMandatory } });
  };
  const navigateComponent = (componentName) => {
    try {
      if (orgId) {
        if (id && !parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${id}/${componentName}/`);
        } else if (id && parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${parentId}/${id}/${componentName}/`);
        } else {
          navigateCourse(`/organization/${orgId}/program/${programId}/${componentName}/`);
        }
      } else {
        if (programId) {
          if (id && !parentId) {
            navigateCourse(`/admin/program/${programId}/section/${id}/${componentName}/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/program/${programId}/section/${parentId}/${id}/${componentName}/`);
          } else {
            navigateCourse(`/admin/program/${programId}/${componentName}/`);
          }
        } else {
          if (id && !parentId) {
            navigateCourse(`/admin/unit-bank/section/${id}/${componentName}/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/unit-bank/section/${parentId}/${id}/${componentName}/`);
          } else {
            navigateCourse(`/admin/unit-bank/${componentName}/`);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  /*
  const portfolioEvidenceHandler = () => {
    try {
      if (orgId) {
        if (id && !parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${id}/portfolio-evidence/`);
        } else if (id && parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${parentId}/${id}/portfolio-evidence/`);
        } else {
          navigateCourse(`/organization/${orgId}/program/${programId}/portfolio-evidence/`);
        }
      } else {
        if (programId) {
          if (id && !parentId) {
            navigateCourse(`/admin/program/${programId}/section/${id}/portfolio-evidence/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/program/${programId}/section/${parentId}/${id}/portfolio-evidence/`);
          } else {
            navigateCourse(`/admin/program/${programId}/portfolio-evidence/`);
          }
        } else {
          if (id && !parentId) {
            navigateCourse(`/admin/unit-bank/section/${id}/portfolio-evidence/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/unit-bank/section/${parentId}/${id}/portfolio-evidence/`);
          } else {
            navigateCourse(`/admin/unit-bank/portfolio-evidence/`);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const courseHandler = () => {
    try {
      if (orgId) {
        if (id && !parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${id}/course-component/`);
        } else if (id && parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${parentId}/${id}/course-component/`);
        } else {
          navigateCourse(`/organization/${orgId}/program/${programId}/course-component/`);
        }
      } else {
        if (programId) {
          if (id && !parentId) {
            navigateCourse(`/admin/program/${programId}/section/${id}/course-component/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/program/${programId}/section/${parentId}/${id}/course-component/`);
          } else {
            navigateCourse(`/admin/program/${programId}/course-component/`);
          }
        } else {
          if (id && !parentId) {
            navigateCourse(`/admin/unit-bank/section/${id}/course-component/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/unit-bank/section/${parentId}/${id}/course-component/`);
          } else {
            navigateCourse(`/admin/unit-bank/course-component/`);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const addFormHandler = () => {
    try {
      if (orgId) {
        if (id && !parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${id}/form/`);
        } else if (id && parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${parentId}/${id}/form/`);
        } else {
          navigateCourse(`/organization/${orgId}/program/${programId}/form/`);
        }
      } else {
        if (programId) {
          if (id && !parentId) {
            navigateCourse(`/admin/program/${programId}/section/${id}/form/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/program/${programId}/section/${parentId}/${id}/form/`);
          } else {
            navigateCourse(`/admin/program/${programId}/form/`);
          }
        } else {
          if (id && !parentId) {
            navigateCourse(`/admin/unit-bank/section/${id}/form/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/unit-bank/section/${parentId}/${id}/ /`);
          } else {
            navigateCourse(`/admin/unit-bank/form/`);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const addResourseHandler = () => {
    try {
      if (orgId) {
        if (id && !parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${id}/add-resources/`);
        } else if (id && parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${parentId}/${id}/add-resources/`);
        } else {
          navigateCourse(`/organization/${orgId}/program/${programId}/add-resources/`);
        }
      } else {
        if (programId) {
          if (id && !parentId) {
            navigateCourse(`/admin/program/${programId}/section/${id}/add-resources/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/program/${programId}/section/${parentId}/${id}/add-resources/`);
          } else {
            navigateCourse(`/admin/program/${programId}/add-resources/`);
          }
        } else {
          if (id && !parentId) {
            navigateCourse(`/admin/unit-bank/section/${id}/add-resources/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/unit-bank/section/${parentId}/${id}/add-resources/`);
          } else {
            navigateCourse(`/admin/unit-bank/add-resources/`);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const addOtjHandler = () => {
    try {
      if (orgId) {
        if (id && !parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${id}/add-off-the-job/`);
        } else if (id && parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${parentId}/${id}/add-off-the-job/`);
        } else {
          navigateCourse(`/organization/${orgId}/program/${programId}/add-off-the-job/`);
        }
      } else {
        if (programId) {
          if (id && !parentId) {
            navigateCourse(`/admin/program/${programId}/section/${id}/add-off-the-job/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/program/${programId}/section/${parentId}/${id}/add-off-the-job/`);
          } else {
            navigateCourse(`/admin/program/${programId}/add-off-the-job/`);
          }
        } else {
          if (id && !parentId) {
            navigateCourse(`/admin/unit-bank/section/${id}/add-off-the-job/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/unit-bank/section/${parentId}/${id}/add-off-the-job/`);
          } else {
            navigateCourse(`/admin/unit-bank/add-off-the-job/`);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
*/
  const addReviewHandler = () => {
    try {
      if (orgId) {
        if (id && !parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${id}/add-review/`);
        } else if (id && parentId) {
          navigateCourse(`/organization/${orgId}/program/${programId}/section/${parentId}/${id}/add-review/`);
        } else {
          navigateCourse(`/organization/${orgId}/program/${programId}/add-review/`);
        }
      } else {
        if (programId) {
          if (id && !parentId) {
            navigateCourse(`/admin/program/${programId}/section/${id}/add-review/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/program/${programId}/section/${parentId}/${id}/add-review/`);
          } else {
            navigateCourse(`/admin/program/${programId}/add-review/`);
          }
        } else {
          if (id && !parentId) {
            navigateCourse(`/admin/unit-bank/section/${id}/add-review/`);
          } else if (id && parentId) {
            navigateCourse(`/admin/unit-bank/section/${parentId}/${id}/add-review/`);
          } else {
            navigateCourse(`/admin/unit-bank/add-review/`);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <>
      <Dropdown
        className="dropdown-select text-end"
        align="end"
      >
        {props.children}
        <div className="dropdown-menu-wrap">
          <Dropdown.Menu
            as={CustomMenu}
            className="dropdown-menu w-dropdown-md"
          >
            {sectionStatus !== false && (
              <Dropdown.Item
                eventKey="1"
                className="section-dropdown-item"
                onClick={() => onAddSectionStatus(true)}
              >
                {unitBankIcon}
                <span
                  className="px-2"
                  title={translate(lang)('section')}
                >
                  {sectionType ? translate(lang)('subSection') : translate(lang)('section')}
                </span>
              </Dropdown.Item>
            )}

            <div className="column-count-2 dropdown-list-item">
              <Dropdown.Item
                eventKey="4"
                onClick={() => navigateComponent('portfolio-evidence')}
              >
                {assignmentDarkIcon}
                <span
                  className="px-2"
                  title={translate(lang)('assignment')}
                >
                  {translate(lang)('assignment')}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="7"
                onClick={() => navigateComponent('add-resources')}
              >
                {resourceIcon}
                <span
                  className="px-2"
                  title={translate(lang)('resources')}
                >
                  {translate(lang)('resources')}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="2"
                onClick={() => navigateComponent('course-component')}
              >
                {coursePlayIcon}
                <span
                  className="px-2"
                  title={translate(lang)('course')}
                >
                  {translate(lang)('course')}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="5"
                onClick={() => navigateComponent('add-off-the-job')}
              >
                {offthejobIcon}
                <span
                  className="px-2"
                  title={translate(lang)('otj')}
                >
                  {translate(lang)('otj')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="5"
                onClick={() => navigateComponent('form')}
              >
                {formBuilderIcon}
                <span
                  className="px-2"
                  title={translate(lang)('otj')}
                >
                  {translate(lang)('forms')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="5"
                onClick={addReviewHandler}
              >
                {reviewNewIcon}
                <span
                  className="px-2"
                  title={translate(lang)('review')}
                >
                  {translate(lang)('review')}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="6"
                onClick={() => navigateComponent('quiz')}
              >
                {quizNewIcon}
                <span
                  className="px-2"
                  title={translate(lang)('quizBank')}
                >
                  {translate(lang)('quizBank')}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="6"
                onClick={() => navigateComponent('add-external-exam')}
              >
                {eventIcon}
                <span
                  className="px-2"
                  title={translate(lang)('externalExam')}
                >
                  {translate(lang)('externalExam')}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="6"
                onClick={() => navigateComponent('add-event')}
              >
                {workflowIcon}
                <span
                  className="px-2"
                  title={translate(lang)('event')}
                >
                  {translate(lang)('event')}
                </span>
              </Dropdown.Item>

              {/* <Dropdown.Item eventKey="6">
                {questionIcon}
                <span className="px-2" title={translate(lang)('quiz')}>
                  {translate(lang)('quiz')}
                </span>
              </Dropdown.Item> */}

              {/* <Dropdown.Item
                eventKey="7"
                onClick={() => navigateComponent('forms')}
              >
                {formIcon}
                <span
                  className="px-2"
                  title={translate(lang)('forms')}
                >
                  {translate(lang)('forms')}
                </span>
              </Dropdown.Item> */}
            </div>
          </Dropdown.Menu>
        </div>
      </Dropdown>
    </>
  );
}

export default AddDropdownList;
