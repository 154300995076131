function AboutInfoControl(props) {
  const { name, description, type } = props;
  return (
    <div className={`about-info-control ${props?.className || ''}`}>
      {name && <span>{name}</span>}
      {description && <div>{description === null ? '' : description}</div>}
    </div>
  );
}

export default AboutInfoControl;
