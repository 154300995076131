import { forwardRef, useState, useEffect } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import Button from 'react-myoneui/lib/Button';
import { Row, Col, Card, ProgressBar, ProgressWrap, ProgressValue, SecondaryHeader, SecondaryHeaderInfo, Badge, Spinner } from 'react-myoneui';
import OberservationEvidence from '../../../components/learners/OberservationEvidence';
import { addIcon, blackArrowLeftIcon, scoreTargetIcon } from '../../../constants/icons';
import ActivitiesDetailList from '../../../components/learners/ActivitiesDetailList';
import AddActivitiesDropdown from './AddActivitiesDropdown';
import ApprenticeHoursList from '../../../components/learners/ApprenticeHoursList';
import RenovationBlock from '../../../components/learners/RenovationBlock';
import RenovationList from '../../../components/learners/RenovationList';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { blackArrowDownIcon } from '../../../constants/icons';
import {
  getUser,
  getTaxonomy,
  deleteMemberActivitiesEvidences,
  viewMemberProgram,
  approveMemberActivity,
  deleteHour,
  fetchMemberActivities,
  getActivities,
  restoreHour,
  getRoles,
  getMemberSection,
} from '../../../api';
import LearnerMainSidebar from '../../../components/LearnerMainSidebar';
import HorizontalNavLinkList from '../components/HorizontalNavLinkList';

import ToastBox from '../../../components/ToastBox';
import AddProgramTargetOffCanvas from './Target/AddProgramTargetOffCanvas';
import { setToastBoxDescription, setToastBoxStatus, setTaxonomy, storeRoles, setActiveSection, storeMemberProgram } from '../../../redux';
import { useDispatch, useSelector } from 'react-redux';

import { displayMonth, formatDate, formatUTCDate, formatUTCDateTime } from '../../../utils/formatTime';
import LearnerRolesFilterSidebar from '../../admin/roles/LearnerRolesFilterSidebar';
import { setSidebarData } from '../../../reducers/sidebarSlice';
import ViewModel from '../components/ViewModal';
import { setConfirmBoxStatus } from '../../../redux/confirmBox';
import { ConfirmBox } from 'components/ConfirmBox';
import ls from 'localstorage-slim';
import { fetchProgramCustomFields, decryptData, translate, setPercentageVariant } from 'lib/common';
import Log from 'lib/Log';
import AddVideoEvidence from './AddVideoEvidence';
import { deleteMemberActivitiesVideoEvidence } from './section';
import { setAlertNotification } from '../../../redux';
import AlertNotification from 'components/AlertNotification';

ls.config.encrypt = true;
function ActivityDetail({ backBtnStatus = true }) {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { programId, otj_id, lectureDetail, id: member_id, activityId, memberSectionId } = useParams();

  const otjId = otj_id ? otj_id : lectureDetail;
  const dispatch = useDispatch();
  const [routePage, setRoutePage] = useState('');
  const [addProgramTargetShow, setAddProgramTargetShow] = useState(false);
  const [programDetails, setProgramDetails] = useState(null);
  const [obtainedScore, setObtainedScore] = useState(0);
  const [programProgress, setProgramProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const [memberActivities, setMemberActivities] = useState([]);
  const [activeActivity, SetActiveActivity] = useState(decryptData(activityId));
  const [currentActiveDetails, setCurrentActiveDetails] = useState({});
  const [totalHours, setTotalHours] = useState(0);
  const [completedTotalOperation, setCompletedTotalOperation] = useState(0);
  const [member, setMember] = useState({});
  const [loadApprove, setLoadApprove] = useState(false);
  const [userId, setUserID] = useState('');
  const [evidenceToRemove, setEvidenceToRemove] = useState({});
  const [evidenceVideoToRemove, setEvidenceVideoToRemove] = useState('');
  const [showVideoOffcanvas, setShowVideoOffcanvas] = useState(false);
  const selectedComponent = useSelector((state) => state.sections.active_component) ?? JSON.parse(ls.get('selectedComponent'));
  const status = selectedComponent?.status ? selectedComponent?.status : selectedComponent?.member_section?.status;

  const can_add_manual_hours = selectedComponent.section.component.can_add_manual_hours;
  const orgId = ls.get('orgId');
  const storedRoles = useSelector((state) => state.roles.list);
  const apprenticeName = storedRoles.filter((role) => role.identifier === 'apprentice')[0]?.name;
  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState();
  const [fileLink, setFileLink] = useState();
  const [fileName, setFileName] = useState();
  const [resetLoad, setResetLoad] = useState(false);
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { can_create_smart_target, can_manage_apprentices } = activeRole?.privileges;
  const role = ls.get('role');
  const pathRole = pathname.split('/')[1];
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const confirmBoxStatus = useSelector((state) => state?.confirmBox?.status);
  const showPageName = () => {
    var programReg = new RegExp(role + '/program');
    var adminApprenticeReg = new RegExp(role + '/users/apprentices');
    var usersApprenticesReg = new RegExp(role + '/apprentices');
    if (programReg.test(pathname)) {
      return 'program';
    } else if (adminApprenticeReg.test(pathname)) {
      return 'apprentices';
    } else if (usersApprenticesReg.test(pathname)) {
      return 'apprentices';
    } else {
      return false;
    }
  };
  const fetchRoles = async () => {
    try {
      const roles = await getRoles(orgId);
      dispatch(storeRoles(roles?.data));
      ls.set('roles', JSON.stringify(roles.data.data));
    } catch (err) {
      Log.info('Error >> ', err);
    }
  };

  //for admin to know whose user's data is viewng
  const fetchMemberData = async () => {
    try {
      const member = await getUser(decryptData(member_id));
      setMember(member.data.member);
    } catch (error) {
      Log.error('Error on : fetchMemberData', error);
    }
  };

  const displayToaster = (msg) => {
    try {
      //  dispatch toaster
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(msg));
    } catch (error) {
      Log.error('Error on : fetchMemberData', error);
    }
  };

  const handleShow = (item) => {
    try {
      setFileType(item?.type || item.link.slice(-3));
      setFileLink(item?.url || item?.link);
      setFileName(item?.i_item_name || item?.name || item?.title);
      setShow(true);
    } catch (error) {
      Log.error('Error on handleShow :', error);
    }
  };
  const init = async () => {
    try {
      var apiStatus = true;

      dispatch(setActiveSection(null));
      if (isLearner) {
        let result = await fetchProgramCustomFields(programId);
        apiStatus = result;
        if (!result) {
          navigate(`/${programId}/program-custom-fields`);
        }
      }
      if (apiStatus) {
        if (isLearner) {
          const element = document.querySelector('main');
          element.classList.remove('main-wrapper');
        } else {
          fetchMemberData();
        }
        fetchProgramDetails();
        setTaxonomyData();
        if (storedRoles.length < 1) {
          fetchRoles();
        }
      }
    } catch (error) {
      Log.error('Error on Log :', error);
    }
  };

  // Sidebar Trigger Components Start
  const setTaxonomyData = async () => {
    try {
      let result = await getTaxonomy();
      dispatch(setTaxonomy({ ...taxonomy, ...result.data }));
    } catch (error) {
      Log.error(error);
    }
  };

  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (!isLearner) {
      dispatch(setSidebarData({ enableActiveWrapper: true }));
      return () => {
        dispatch(setSidebarData({ enableActiveWrapper: false }));
      };
    }
  }, [dispatch, !isLearner]);

  useEffect(() => {
    if (!isLearner) {
      if (!enableActiveWrapper) {
        setTimeout(() => {
          dispatch(setSidebarData({ enableActiveWrapper: true }));
        }, 1);
      }
    }
  }, [enableActiveWrapper]);

  const onClickHandler = (clicked_activity) => {
    SetActiveActivity(clicked_activity);
  };

  useEffect(() => {
    if (memberActivities.length > 0 && activeActivity) {
      const activeMemberActivity = memberActivities.filter((memberActivity) => memberActivity.id === activeActivity);
      const nonDeletedHours = activeMemberActivity[0]?.hours?.filter((hour) => !hour.deleted);
      const calculateTotalHour = nonDeletedHours?.reduce((acc, hour) => hour.total_hours + acc, 0);
      setTotalHours(calculateTotalHour);
      setCurrentActiveDetails(activeMemberActivity[0]);
      // operation
      const calcuatedOperation = nonDeletedHours?.reduce((acc, operation) => operation.completed_operation + acc, 0);
      setCompletedTotalOperation(calcuatedOperation);
    }
  }, [activeActivity, memberActivities]);

  const fetchProgramDetails = async () => {
    try {
      const payload = {
        orgId: ls.get('orgId'),
        memberProgramId: programId,
      };
      setLoading(true);
      const { data } = await viewMemberProgram(payload);
      setUserID(data.data.member.user_id);
      // Setting Program Detail
      setProgramDetails(data.data);
      setObtainedScore(data.data.score);
      calculateProgress(data.data);
      getMemberActivities();
      setLoading(false);
      dispatch(storeMemberProgram({ ...data.data }));
    } catch (err) {
      setLoading(false);
      dispatch(setToastBoxDescription('Something went wrong while fetching data.'));
      dispatch(setToastBoxStatus(true));

      Log.error('Error >> ', err);
    }
  };

  const getMemberActivities = async () => {
    try {
      // fetch activities of OTJ component created by admin
      setActivityLoading(true);
      const res = await getActivities(decryptData(otjId));
      const formattedItems = res.data.data[0].activities.map((activity) => ({
        id: activity._id,
        name: activity.title,
        total_operation: activity?.total_operation,
      }));
      // fetch member activities
      /* Member Section can be get from params memberSectionId via Notification */
      const payload = {
        member: isLearner ? ls.get('userid') : decryptData(member_id),
        member_section: decryptData(memberSectionId) || selectedComponent?.member_section?.id || selectedComponent?.id,
        otj: decryptData(otjId),
      };
      const { data } = await fetchMemberActivities(payload);

      // now we have to replace activity by activity title if activity exist
      const formatedActivities = [];
      data.data.memberActivities.forEach((memberActivity) => {
        if (memberActivity.activity) {
          formattedItems.forEach((activity) => {
            if (activity.id === memberActivity.activity) {
              formatedActivities.push({ ...memberActivity, activity: activity.name, total_operation: activity?.total_operation });
            }
          });
        } else {
          formatedActivities.push(memberActivity);
        }
      });

      setMemberActivities(formatedActivities);
      setActivityLoading(false);

      if (activeActivity) {
        SetActiveActivity(activeActivity);
      }
    } catch (error) {
      setActivityLoading(false);
      Log.info('Error : getMemberActivities >> ', error);

      // setLoading(false);
    }
  };

  const handleAddedNewHour = (hour) => {
    try {
      let updatedMemberActivities = [...memberActivities];
      const currentActiveIndex = memberActivities.map((memberActivity) => memberActivity.id === currentActiveDetails.id).indexOf(true);
      updatedMemberActivities[currentActiveIndex].hours.push(hour);
      setMemberActivities(updatedMemberActivities);
    } catch (error) {
      Log.error('Error on handleAddedNewHour :', error);
    }
  };

  const handleAddedNewEvidences = (evidences) => {
    try {
      let updatedMemberActivities = [...memberActivities];
      const currentActiveIndex = memberActivities.map((memberActivity) => memberActivity.id === currentActiveDetails.id).indexOf(true);
      updatedMemberActivities[currentActiveIndex].evidences = evidences;
      setMemberActivities(updatedMemberActivities);
    } catch (error) {
      Log.error('Error on handleAddedNewEvidences :', error);
    }
  };

  const removeEvidence = async (evidence) => {
    try {
      const { data } = await deleteMemberActivitiesEvidences({ member_activity: activeActivity, evidence_id: evidence.id });

      if (data) {
        let updatedMemberActivities = [...memberActivities];
        const currentActiveIndex = memberActivities.map((memberActivity) => memberActivity.id === currentActiveDetails.id).indexOf(true);
        updatedMemberActivities[currentActiveIndex].evidences = data.data.evidences;
        setMemberActivities(updatedMemberActivities);
        dispatch(setConfirmBoxStatus(false));
        dispatch(setToastBoxStatus(true));
        setEvidenceToRemove({});
        dispatch(setToastBoxDescription(data?.message));
      }
    } catch (error) {
      Log.error('Error on removeEvidence :', error);
    }
  };

  const calculateProgress = (memberProgram) => {
    /* if (memberProgram.program.score && memberProgram.program.score_type == 'manual') {
      setProgramProgress(Math.round((memberProgram.score / memberProgram.program.score) * 100 * 100) / 100);
    } else */ if (memberProgram.program.progress_calculated_on === 'completion') {
      setProgramProgress(memberProgram.completed_progress);
    } else {
      setProgramProgress(memberProgram.submitted_progress);
    }
  };

  const approveActivity = async (status) => {
    try {
      setLoadApprove(true);
      const { data } = await approveMemberActivity({ member_section_id: decryptData(memberSectionId), is_approved: status, member_activity_id: currentActiveDetails.id });
      setCurrentActiveDetails({ ...currentActiveDetails, is_approved: status });
      getMemberActivities();
    } catch (err) {
      Log.info('Error >> ', err);
    } finally {
      setLoadApprove(false);
    }
  };

  const updateMemberActivities = (hours) => {
    // update memberActivities with deleted or restored hour by finding exact memberActivity
    let updateMemberActivities = [...memberActivities];
    const memberActivitiesIndexes = memberActivities.map((memberActivity, index) => (memberActivity.id === currentActiveDetails.id ? index : -1));
    const activeIndex = memberActivitiesIndexes.filter((index) => index !== -1);
    updateMemberActivities[activeIndex].hours = hours;
    setMemberActivities(updateMemberActivities);
    setCurrentActiveDetails({ ...currentActiveDetails, hours });
  };

  const handleDeleteHour = async (payload) => {
    try {
      const { data } = await deleteHour(payload);
      updateMemberActivities(data.data);
    } catch (err) {
      Log.info('Error >> ', err);
    }
  };

  const validateOperation = (data) => {
    let result = true;
    const privilege = selectedComponent?.section?.component?.can_create_operation;
    if (privilege && completedTotalOperation + data > currentActiveDetails?.total_operation) {
      setResetLoad(true);
      setTimeout(() => {
        setResetLoad(false);
      }, 500);
      dispatch(
        setAlertNotification({
          msg: `Invalid ${translate(taxonomy)('operation')}'s. While restoring activity completed ${String(translate(taxonomy)('operation')).toLocaleLowerCase()}'s will be greater than total ${String(
            translate(taxonomy)('operation'),
          ).toLocaleLowerCase()}'s`,
          alertStatus: false,
          title: 'Request Failed',
        }),
      );
      result = false;
    } else {
      result = true;
    }
    return result;
  };

  const reStoreDeleteHour = async (payload) => {
    try {
      if (!validateOperation(payload?.completed_operation)) {
        return;
      }
      const filtered_payload = { member_activity: payload.member_activity, hour_id: payload.hour_id };
      const { data } = await restoreHour(filtered_payload);
      updateMemberActivities(data.data);
    } catch (err) {
      Log.info('Error >> ', err);
      dispatch(
        setAlertNotification({
          msg: err?.data?.message,
          alertStatus: false,
          title: 'Request Failed',
        }),
      );
      setResetLoad(true);
    } finally {
      setTimeout(() => {
        setResetLoad(false);
      }, 500);
    }
  };
  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  async function downloadFile(myfile) {
    Log.info(myfile);
    let url = myfile?.link ? myfile.link : myfile?.url;
    const aElement = document.createElement('a');
    aElement.setAttribute('download', myfile.name);
    aElement.href = await toDataURL(url);
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(url);
  }
  const fetchSectionDetail = async (id) => {
    // getMemberSection
    try {
      setLoading(true);

      let result = await getMemberSection(id);
      if (result) {
        setLoading(false);
        dispatch(setActiveSection(result.data?.data));
      }
    } catch (err) {
      setLoading(false);
      Log.error(err);
    }
  };
  /* If we got memberSectionId from URL params then need to fill selectedComponent
   and will reflect that selected into localstore too 
   for evidence upload purpose */
  useEffect(() => {
    if (memberSectionId) {
      fetchSectionDetail(decryptData(memberSectionId));
    }
  }, [memberSectionId]);

  const handleOpenAddVideo = () => {
    setShowVideoOffcanvas(!showVideoOffcanvas);
  };

  const handleVideoEvidenceDelete = async (videoId) => {
    try {
      const payload = {
        member_activity: currentActiveDetails?.id,
        video_evidence_id: videoId,
      };
      const result = await deleteMemberActivitiesVideoEvidence(payload);
      if (result) {
        setCurrentActiveDetails({ ...currentActiveDetails, video_evidences: currentActiveDetails.video_evidences?.filter((v) => v?.id !== videoId) });
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
      }
    } catch (error) {
    } finally {
      dispatch(setConfirmBoxStatus(false));
      setEvidenceVideoToRemove('');
    }
  };

  const handleSaveVideoEvidence = (data) => {
    setCurrentActiveDetails({ ...currentActiveDetails, video_evidences: data });
  };

  const handleUpdateNewTag = (newTagVideo) => {
    const updatedVideo = currentActiveDetails.video_evidences?.map((video) => (video?.id === newTagVideo?.id ? newTagVideo : video));
    setCurrentActiveDetails({ ...currentActiveDetails, video_evidences: updatedVideo });
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      role="button"
      tabIndex={0}
      ref={ref}
      variant="outline"
      className="dark"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  return (
    <>
      <AlertNotification />
      {isLearner && (
        <>
          <HorizontalNavLinkList navKey="link-2" />
          <SecondaryHeader>
            <div className="secondary-header-wrap">
              <SecondaryHeaderInfo SecondaryHeaderTitle={programDetails?.program?.name ? programDetails?.program?.name : ''} />
            </div>
            <div className="button-wrap gap-4">
              {programDetails && (
                <>
                  {programDetails?.program?.score > 0 && (
                    <div className="update-score-link">
                      <span className="update-score-icon">{scoreTargetIcon}</span>
                      {`${obtainedScore}/${programDetails.program?.score}`}
                    </div>
                  )}
                  <span className="separator"></span>
                  <div className="progressbar-holder">
                    <ProgressWrap>
                      {' '}
                      <ProgressValue ProgressPercentage={`${programProgress}%`} /> &nbsp;
                      <ProgressBar
                        variant={setPercentageVariant(programProgress)}
                        now={programProgress}
                      />{' '}
                    </ProgressWrap>
                  </div>
                  <span className="separator"></span>
                  {programDetails?.status === 'Not Started' && <Badge className="badge dark">{programDetails?.status}</Badge>}
                  {programDetails?.status === 'In Progress' && <Badge className="badge blue">{programDetails?.status}</Badge>}
                  {programDetails?.status === 'Completed' && <Badge className="badge">{programDetails?.status}</Badge>}
                  {programDetails?.status === 'Submitted' && <Badge className="badge warning">{programDetails?.status}</Badge>}
                  <span className="separator"></span>{' '}
                  <Button
                    variant="leading-icon"
                    className="leading-icon add-target-btn m-0"
                    type="button"
                    onClick={() => {
                      setAddProgramTargetShow(true);
                    }}
                  >
                    {addIcon}
                    {`Add ${translate(taxonomy)('target')}`}
                  </Button>
                </>
              )}
            </div>
          </SecondaryHeader>
          <LearnerMainSidebar />
        </>
      )}
      <div className="content-wrapper pb-0">
        {!isLearner && (
          <>
            <SecondaryHeader>
              <div className="secondary-header-wrap">
                <SecondaryHeaderInfo
                  SecondaryHeaderTitle={`${member?.first_name ? member?.first_name : ''} ${member?.last_name ? member?.last_name : ''}`}
                  SecondaryHeaderText={member.decrypted_email}
                  SecondaryText
                />
              </div>
              <div className="button-wrap gap-4">
                {programDetails && (
                  <>
                    {programDetails?.program?.score > 0 && (
                      <div className="update-score-link">
                        {/* <span className="update-score-icon">{scoreTargetIcon}</span> */}
                        {`${programDetails?.score}/${programDetails.program?.score}`}
                      </div>
                    )}
                    <span className="separator"></span>
                    <ProgressWrap>
                      {' '}
                      <ProgressValue ProgressPercentage={`${programProgress}%`} /> &nbsp;
                      <ProgressBar
                        variant={setPercentageVariant(programProgress)}
                        now={programProgress}
                      />{' '}
                    </ProgressWrap>
                  </>
                )}
                <Badge className="badge dark">{programDetails?.status}</Badge>
                {((!isLearner && can_create_smart_target) || isLearner) && (
                  <Button
                    variant="leading-icon"
                    className="leading-icon add-target-btn m-0"
                    type="button"
                    onClick={() => {
                      setAddProgramTargetShow(true);
                    }}
                  >
                    {addIcon} {`Add ${translate(taxonomy)('target')}`}
                  </Button>
                )}
              </div>
            </SecondaryHeader>
            <LearnerRolesFilterSidebar
              backpage={showPageName()}
              profileId={member_id}
            />
          </>
        )}
        {loading ? (
          <div className="spinner-middle">
            <Spinner animation="border" />
          </div>
        ) : (
          <div className="content-block bg-transparent p-0 mb-0">
            {setConfirmBoxStatus && (
              <ConfirmBox
                show={confirmBoxStatus}
                question={`Are you sure you want to remove this ${evidenceVideoToRemove ? 'Video' : 'Evidence'}?`}
                okText="Yes"
                cancelText="No"
                onHide={() => {
                  dispatch(setConfirmBoxStatus(false));
                }}
                onOk={() => (evidenceVideoToRemove ? handleVideoEvidenceDelete(evidenceVideoToRemove) : removeEvidence(evidenceToRemove))}
              />
            )}
            <Row>
              <Col
                xl={4}
                lg={12}
                className="mb-4"
              >
                <Card className="border-0 h-100">
                  <Card.Body>
                    {state?.otj && Object.entries(state.otj).length > 0 && (
                      <div className="activities-detail-wrap">
                        <h4>{state.otj.title}</h4>
                        {/* <div className="activities-detail-weightage">
                          <span>Welcome Resource &#40;Section&#41;</span>
                          <span>
                            Weightage: <strong>12%</strong>
                          </span>
                        </div> */}
                      </div>
                    )}
                    <div className="activities-detail-header">
                      <h4>
                        {translate(taxonomy)('activity')} &#40;{memberActivities.length}&#41;
                      </h4>
                    </div>
                    {/* memberActivities {JSON.stringify(memberActivities)} */}

                    {activityLoading ? (
                      <div className="spinner-middle">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      memberActivities.map((activity, i) => (
                        <ActivitiesDetailList
                          key={i}
                          className={`d-block cursor-pointer ${activeActivity === activity.id ? 'active' : ''}`}
                          activityId={activity.id}
                          title={activity.title !== '' ? activity.title : activity.activity}
                          location={activity.location}
                          hours={activity.hours}
                          // name="Alex Mathew" it is name of supervisor
                          status={activity.is_approved}
                          schedule={displayMonth(activity.date)}
                          handleClick={onClickHandler}
                          innerActivitiesDetails={true}
                        />
                      ))
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xl={8}
                lg={12}
                className="mb-4"
              >
                <Card className="border-0 h-100">
                  <Card.Body>
                    <div className="program-list-detail-block">
                      <div className="program-fixed-header">
                        <>
                          {backBtnStatus && (
                            <Button
                              variant="text-icon"
                              className="p-0 back-btn"
                              onClick={() => navigate(-1)}
                            >
                              {blackArrowLeftIcon} Back
                            </Button>
                          )}
                        </>
                        {isLearner && (
                          <>
                            {currentActiveDetails.is_approved === true ? (
                              <span class="status-label status-approved">Approved</span>
                            ) : currentActiveDetails.is_approved === false ? (
                              <span className="status-label status-rejected">Disapproved</span>
                            ) : (
                              ''
                            )}
                            {!activityLoading && (
                              <div className="program-button-wrap">
                                <AddActivitiesDropdown
                                  userId={userId}
                                  activeActivity={activeActivity}
                                  addedNewEvidences={(evidences) => handleAddedNewEvidences(evidences)}
                                  addedNewHour={(hour) => handleAddedNewHour(hour)}
                                  canAddManualHours={can_add_manual_hours}
                                  activityDetail={currentActiveDetails}
                                  completedTotalOperation={completedTotalOperation}
                                >
                                  <Dropdown.Toggle
                                    as={CustomToggle}
                                    id="dropdown-custom-components"
                                  >
                                    Add
                                    <span className="drop-arrow">{blackArrowDownIcon}</span>
                                  </Dropdown.Toggle>
                                </AddActivitiesDropdown>
                              </div>
                            )}
                          </>
                        )}

                        {!isLearner && currentActiveDetails && can_manage_apprentices && status !== 'Completed' && (
                          <>
                            {currentActiveDetails.is_approved === true ? (
                              <span class="status-label status-approved">Approved</span>
                            ) : currentActiveDetails.is_approved === false ? (
                              <span className="status-label status-rejected">Disapproved</span>
                            ) : (
                              ''
                            )}

                            {(currentActiveDetails.is_approved == true || currentActiveDetails.is_approved === null) && selectedComponent?.status !== 'Completed' && (
                              <Button
                                variant="orange"
                                className="m-2"
                                onClick={() => {
                                  approveActivity(false);
                                }}
                              >
                                Disapprove
                              </Button>
                            )}

                            {(currentActiveDetails.is_approved == false || currentActiveDetails.is_approved === null) && selectedComponent?.status !== 'Completed' && (
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  approveActivity(true);
                                }}
                              >
                                Approve
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                      {currentActiveDetails && !activityLoading && (
                        <div className="program-list-detail-body">
                          <div className="program-detial-header-wrap">
                            <div className="program-detial-info">
                              <h4>{currentActiveDetails.title?.trim().length > 0 ? currentActiveDetails.title : currentActiveDetails.activity}</h4>
                              {/* <span>Understand the principles of health, safety and welfare and how these are applied in the workplace</span> */}
                            </div>
                          </div>
                          <RenovationBlock>
                            <RenovationList
                              title="Date"
                              date={displayMonth(currentActiveDetails.date)}
                            />
                            {/* <RenovationList title="Start Date" date="24 March 2022" time="12:30 pm" />
                            <RenovationList title="End Date" date="15 Jan 2023" time="14:45 pm" />
                            <RenovationList title="Supervisor" name="Alex Mathew" /> */}
                            <RenovationList
                              title="Location"
                              location={currentActiveDetails.location}
                            />
                            <RenovationList
                              title="Total Hours"
                              location={totalHours}
                            />
                            {selectedComponent?.section?.component?.can_create_operation && (
                              <RenovationList
                                title={`Total ${translate(taxonomy)('operation')}'s`}
                                location={`${completedTotalOperation} / ${currentActiveDetails?.total_operation}`}
                              />
                            )}
                            {/* <RenovationList title="Status" status="Overdue" /> */}
                          </RenovationBlock>
                          Hours {currentActiveDetails?.hours?.length}
                          <div className="apprentice-hours-block">
                            <h4>{apprenticeName} Hours</h4>

                            {currentActiveDetails.hours?.map((hour, i) => (
                              <ApprenticeHoursList
                                key={i}
                                status={status}
                                schedule={`${formatUTCDateTime(hour.start_time)} to ${formatUTCDateTime(hour.end_time)}`}
                                totalHour={`Total Hrs ${hour.total_hours} Hours`}
                                completedOperation={
                                  selectedComponent?.section?.component?.can_create_operation
                                    ? `Completed ${translate(taxonomy)('operation')}'s: ${hour.completed_operation} ${translate(taxonomy)('operation')}'s`
                                    : ''
                                }
                                description={hour.description}
                                deleted={hour.deleted}
                                deleteHour={() => handleDeleteHour({ member_activity: currentActiveDetails.id, hour_id: hour.id })}
                                restoreHour={() => reStoreDeleteHour({ member_activity: currentActiveDetails.id, hour_id: hour.id, completed_operation: hour.completed_operation })}
                                canAdminPerformAction={{ isValidRole: !isLearner, can_manage_apprentices }}
                                resetLoad={resetLoad}
                              />
                            ))}
                            {/* <ApprenticeHoursList
                              schedule="12 September 2022, 12.30pm to 12 October 2022, 12.30pm"
                              totalHour="Total Hrs 12 Hours"
                              description="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a ore-or-less normal distribution of letters, as opposed to using ‘Content here, content"
                            /> */}
                          </div>
                          {currentActiveDetails?.evidences?.length > 0 && (
                            <div className="feature-video-block mb-2">
                              <h4>Media</h4>
                              <Row>
                                <Col
                                  sm={12}
                                  lg={10}
                                  xl={8}
                                >
                                  <div className="program-list-observation ellipsis-content">
                                    {currentActiveDetails.evidences?.map((evidence, i) => (
                                      <OberservationEvidence
                                        link={evidence.url}
                                        onDelete={(data) => {
                                          setEvidenceToRemove(data);
                                          dispatch(setConfirmBoxStatus(true));
                                        }}
                                        title={evidence?.i_item_name || evidence.name}
                                        data={evidence}
                                        key={evidence._id}
                                        type={evidence.type}
                                        // status={selectedComponent?.member_section?.status}
                                        viewBtn
                                        removeBtn
                                        onShowModel={() => handleShow(evidence)}
                                        downloadBtn={() => {
                                          downloadFile(evidence);
                                        }}
                                      />
                                      // <OberservationEvidence key={i} title={evidence.name} viewBtn removeBtn />
                                    ))}
                                    {/* <OberservationEvidence title="Preparatory Batch for Judiciary Exams in English" totalMb="12.8 MB" viewBtn removeBtn /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                          <div className="">
                            {isLearner && currentActiveDetails && !currentActiveDetails?.video_evidences?.length && (
                              <Card.Link
                                href="#"
                                onClick={handleOpenAddVideo}
                              >
                                {addIcon}
                                Add Video
                              </Card.Link>
                            )}
                            {(showVideoOffcanvas || (currentActiveDetails && currentActiveDetails?.video_evidences?.length > 0)) && (
                              <AddVideoEvidence
                                member_activity={currentActiveDetails?.id}
                                onSave={(video, newTag) => {
                                  video && handleSaveVideoEvidence(video);
                                  newTag && handleUpdateNewTag(newTag);
                                }}
                                oldVideo={currentActiveDetails?.video_evidences}
                                removeVideo={(data) => {
                                  setEvidenceVideoToRemove(data);
                                  dispatch(setConfirmBoxStatus(true));
                                }}
                                canAccessAll={isLearner || can_manage_apprentices}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {activityLoading && (
                        <div className="spinner-middle">
                          <Spinner animation="border" />
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>

      <AddProgramTargetOffCanvas
        show={addProgramTargetShow}
        onClose={() => setAddProgramTargetShow(false)}
        displayMsg={(msg) => displayToaster(msg)}
        programDetails={programDetails}
        updatedTargets={(program) => setProgramDetails(program)}
      />
      <ViewModel
        onClose={() => setShow(false)}
        show={show}
        fileType={fileType}
        fileLink={fileLink}
        fileName={fileName}
      />
      <ToastBox />
    </>
  );
}
export default ActivityDetail;
