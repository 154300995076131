import { useEffect, useState } from 'react';
import { Form } from 'react-myoneui';
import { useSelector, useDispatch } from 'react-redux';
import { storeFormData } from '../redux/FormDataSlice';
import Log from 'lib/Log';

const LabeledRating = ({ value = 0, readOnly = false, onChange, data, ...props }) => {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id)) || {};
  const formData = useSelector((state) => state.formData.data) || {};
  const dispatch = useDispatch();

  const valueControl = (e) => {
    try {
      let cloneFormData = JSON.parse(JSON.stringify(formData));
      let obj = cloneFormData.find((item) => item.id === e.target.id);
      let index = cloneFormData.indexOf(obj);
      obj.rating = e.target.value;
      cloneFormData[index] = obj;
      dispatch(storeFormData(cloneFormData));
    } catch (error) {
      Log.error(error);
    }
  };
  return (
    <>
      <div className="labeled-rating mt-4">
        <div>
          <Form.Check className="checkbox-border">
            <Form.Check.Input
              className="form-check-input"
              type="radio"
              id={state?.id}
              name={state?.id}
              value="1"
              onChange={valueControl}
              checked={state?.rating == 1}
            />
          </Form.Check>
          <Form.Label
            className="form-check-label"
            htmlFor="radio"
          >
            1
          </Form.Label>
        </div>
        <div>
          <Form.Check className="checkbox-border">
            <Form.Check.Input
              className="form-check-input "
              type="radio"
              id={state?.id}
              name={state?.id}
              value="2"
              onChange={valueControl}
              checked={state?.rating == 2}
            />
          </Form.Check>
          <Form.Label
            className="form-check-label"
            htmlFor="radio"
          >
            2
          </Form.Label>
        </div>
        <div>
          <Form.Check className="checkbox-border">
            <Form.Check.Input
              className=""
              type="radio"
              id={state?.id}
              name={state?.id}
              value="3"
              onChange={valueControl}
              checked={state?.rating == 3}
            />
          </Form.Check>
          <Form.Label
            className="form-check-label"
            htmlFor="radio"
          >
            3
          </Form.Label>
        </div>
        <div>
          <Form.Check className="checkbox-border">
            <Form.Check.Input
              className="form-check-input "
              type="radio"
              id={state?.id}
              name={state?.id}
              value="4"
              onChange={valueControl}
              checked={state?.rating == 4}
            />
          </Form.Check>
          <Form.Label
            className="form-check-label"
            htmlFor="radio"
          >
            4
          </Form.Label>
        </div>
        <div>
          <Form.Check className="checkbox-border">
            <Form.Check.Input
              className="form-check-input "
              type="radio"
              id={state?.id}
              name={state?.id}
              value="5"
              onChange={valueControl}
              checked={state?.rating == 5}
            />
          </Form.Check>
          <Form.Label
            className="form-check-label"
            htmlFor="radio"
          >
            5
          </Form.Label>
        </div>
      </div>
    </>
  );
};
export default LabeledRating;
