import ProgramOverviewList from './ProgramOverviewList';

export default function ProgramOverview({ items }) {
  return (
    <div className="program-overview">
      {items.map((item, index) => (
        <ProgramOverviewList
          key={index}
          title={item.title}
          progressVariant={item.progressVariant}
          progressValue={item.progressValue}
          count={item.count}
        />
      ))}
    </div>
  );
}
