import { memo, useEffect, useState } from 'react';
import { Form, Col } from 'react-myoneui';
import DropdownCollection from 'components/DropdownCollection';

function InputDropdown({ data }) {
  const [state, setState] = useState({ ...data });
  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <>
      <Form.Label
        htmlFor={state.label + state.id}
        className="form-builder-label"
      >
        {state.label}
      </Form.Label>
      <DropdownCollection
        // label={state?.label}
        items={state?.dropdownItems.map((list) => ({ name: list, id: list }))}
      />
    </>
  );
}
export default memo(InputDropdown);
