import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setElements, setMode, updateElement, setActiveEle } from '../../../redux/FormBuilderSlice';
import { Form, FloatingLabel, Button, Spinner, Row, Card } from 'react-myoneui';
import DropdownCollection from '../../../components/DropdownCollection';
import DropdownCheckboxMultiSelect from 'components/DropdownCheckboxMultiSelect';
import { addIcon, binIcon } from 'constants/icons';
import { Link } from 'react-router-dom';
import { editFormField } from 'api/formBuilder';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
ls.config.encrypt = true;
function EditMCQElement({ data = {}, onSave, rolesList, displayRoles }) {
  const orgId = ls.get('orgId');
  const [state, setState] = useState({});
  const dispatch = useDispatch(useDispatch);
  const [loading, setLoading] = useState(false);
  const fieldWidthOptions = [
    { id: 1, name: 'Full width', value: 12 },
    { id: 2, name: 'Half width', value: 6 },
    { id: 3, name: '1/3 width', value: 4 },
    { id: 4, name: '3/4 width', value: 8 },
  ];
  const elements = useSelector((state) => state.formbuilder.elements);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setState(activeEle);
    setRoles(rolesList.filter((item) => activeEle?.editable_by.includes(item.id)).map((item) => item.name));
  }, [activeEle, rolesList]);
  const editHandler = (e) => {
    try {
      let cloneState = JSON.parse(JSON.stringify(state));
      cloneState[e.target.name] = e.target.value;
      setState(cloneState);
    } catch (error) {
      Log.error(error);
    }
  };

  const saveHander = async () => {
    try {
      let cloneElements = [...elements];
      let element = cloneElements.find((item) => item.id === state.id);
      let index = cloneElements.indexOf(element);
      cloneElements[index] = { ...state };
      setLoading('loading');
      await editFormField({ ...state });
      dispatch(setActiveEle({ ...state }));
      setLoading(true);
      dispatch(setMode('view'));
    } catch (error) {
      Log.error(error);
      setLoading(true);
    }
  };
  const changeSize = (obj) => {
    setState({ ...state, col_width: obj.value });
  };

  const onPeriodItemsChange = (item) => {
    try {
      setRoles((roles) => {
        const prevItems = [...roles];
        const index = prevItems.indexOf(item);
        if (index === -1) {
          return [...prevItems, item];
        } else {
          prevItems.splice(index, 1);
          return prevItems;
        }
      });
    } catch (err) {
      Log.error(err);
    }
  };

  useEffect(() => {
    if (roles.length > 0) {
      setState({ ...state, editable_by: rolesList.filter((item) => roles.includes(item.name)).map((item) => item.id) });
    }
  }, [roles]);

  //   mcq start here
  // const mcqTypeList = [
  //   { name: 'Single Select', id: 'Single Select' },
  //   { name: 'Multi Select', id: 'Multi Select' },
  //   { name: 'Multiple Input', id: 'Multiple Input' },
  // ];

  const changeMCQType = (e) => {
    setState({ ...state, mcq_type: e.id });
  };

  //   single select

  const [items, setItems] = useState([{ value: false, label: '' }]);
  const handleItemsLabelChange = (e) => {
    const { value } = e.target;
    const index = e.target.dataset.index;
    if (index !== undefined) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index] = {
          ...updatedItems[index],
          label: value,
        };
        setState((prevFormData) => ({
          ...prevFormData,
          mcq_option: updatedItems,
        }));
        return updatedItems;
      });
    }
  };

  const handleOptionDelete = (index) => {
    setItems((prevItems) => {
      const itemsClone = [...prevItems];
      itemsClone.splice(index, 1);
      setState((prevState) => ({
        ...prevState,
        mcq_option: itemsClone,
      }));
      return itemsClone;
    });
  };

  const addItems = () => {
    setItems((prevItems) => [...prevItems, { value: false, label: '', type: 'Single Select' }]);
  };

  useEffect(() => {
    setItems(state?.mcq_option);
  }, [state]);

  return (
    <div className="form-builder-edit-option">
      {fieldWidthOptions && state.col_width && (
        <DropdownCollection
          className="mb-28"
          defaultValue={fieldWidthOptions.find((item) => item.value === state.col_width)}
          items={fieldWidthOptions}
          label={'Field Width'}
          onChange={(obj) => changeSize(obj)}
        />
      )}
      <DropdownCheckboxMultiSelect
        className="mb-4 user-max-content-dropdown"
        label="Editable By"
        name="editable_by"
        autoComplete="off"
        items={displayRoles}
        selectedItems={roles}
        onChange={onPeriodItemsChange}
      />
      {/* mcq start here */}

      {/* <DropdownCollection
        className="mb-28"
        defaultValue={{ name: state?.mcq_type, id: state?.mcq_type }}
        items={mcqTypeList}
        label={'MCQ Type'}
        onChange={changeMCQType}
      /> */}
      <FloatingLabel
        controlId="labelId"
        label="Question *"
        className="mb-28"
      >
        <Form.Control
          type="text"
          placeholder="Question *"
          value={state.label}
          onChange={editHandler}
          name="label"
        />
      </FloatingLabel>

      <Row className="bg-gray mb-3 p-2">
        <h4 className="level_fs">Options:</h4>
        {items &&
          items?.length > 0 &&
          items.map((item, index) => {
            return (
              <div className="d-flex flex-col mb-1">
                <Form.Check
                  value=""
                  name="single"
                  type="radio"
                  className="align-items-center pt-3 pe-1"
                />
                <FloatingLabel
                  controlId={`floatingInputAdvantage${index}`}
                  label="eg: option1, option2, ..."
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={`floatingInputAdvantage${index}`}
                    data-index={index}
                    onChange={(e) => handleItemsLabelChange(e, index)}
                    value={item?.label}
                  />
                </FloatingLabel>
                <Link
                  to=""
                  className="ps-1 pt-3"
                  onClick={() => handleOptionDelete(index)}
                  data-index={index}
                >
                  {binIcon}
                </Link>
              </div>
            );
          })}
        <span className="card-divider my-1"></span>
        <div className="card-link-wrap">
          <p className="help-text mb-0">Please add required items. Empty items will not be valid.</p>
          <Card.Link
            href="#"
            onClick={addItems}
            className="text-primary"
          >
            {addIcon}
            Add more
          </Card.Link>
        </div>
      </Row>
      <div className="button-wrap">
        <Button
          variant="secondary"
          onClick={saveHander}
          disabled={items?.length < 2 || items?.some((item) => !item.label || item.label === '' || item.label.trim() === '') || roles?.length < 1}
        >
          {loading === 'loading' && (
            <Spinner
              animation="border"
              size="sm"
            />
          )}
          Save
        </Button>
      </div>
    </div>
  );
}
export default memo(EditMCQElement);
