import { memo, useEffect, useState } from 'react';
import { Form, Col } from 'react-myoneui';

function InputMCQ({ data }) {
  const [state, setState] = useState({ ...data });
  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <>
      <Form.Label
        htmlFor={state.label + state.id}
        className="form-builder-label mb-0"
      >
        {state.label}
      </Form.Label>
      {state?.mcq_option?.length > 0
        ? state.mcq_option.map((item, index) => {
            return (
              <div className="d-flex flex-col mb-1 ms-2">
                <Form.Check
                  value=""
                  label={item.label}
                  name="single"
                  type="radio"
                  className="align-items-center pt-3 pe-1"
                />
              </div>
              //   </Col>
              // </Row>
            );
          })
        : ''}
    </>
  );
}
export default memo(InputMCQ);
