import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const saveObservation = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.post(`${API_URL}/apprentice/create/observation`, { ...payload }, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const setStatusComplete = async ({ orgId, payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/admin/update/markAsComplete`, { ...payload }, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateLastAssedAt = async ({ orgId, payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/update/lastAssessedAt`, { ...payload }, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const addEvidences = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/add/evidences`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const addVideoEvidences = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/add/video-evidences`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateVideoEvidences = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.patch(`${API_URL}/apprentice/update/video-evidences`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const removeVideoEvidences = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${API_URL}/apprentice/delete/video-evidence`, { headers, data: payload });
  } catch (err) {
    throw new Error(err);
  }
};

export const addMemberActivityEvidences = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/OTJ/activity/addEvidence`, payload, headers);
  } catch (err) {
    throw err;
  }
};

export const deleteEvidences = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,

    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${API_URL}/apprentice/delete/evidence`, { headers, data: payload });
  } catch (err) {
    throw err;
  }
};

export const deleteMemberActivitiesEvidences = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${API_URL}/apprentice/OTJ/activity/removeEvidence`, { headers, data: payload });
  } catch (err) {
    throw err;
  }
};

export const updateStatus = async ({ orgId, payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/update/status`, { ...payload }, headers);
  } catch (err) {
    throw err;
  }
};

// remainder
export const sendReminder = async ({ orgId, payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/admin/set-reminder`, { ...payload }, headers);
  } catch (err) {
    throw err;
  }
};
