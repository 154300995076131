import { createSlice } from '@reduxjs/toolkit';

const fields = [
  { id: 1, name: 'section', title: 'Section', heading: 'Section 1', sub_heading: 'Single line or multiline text area', status: true, draggable: true, index: null },
  { id: 2, name: 'sub-section', title: 'Sub Section', heading: 'Sub Section 1', sub_heading: 'Single line or multiline text area', status: true, draggable: true, index: null },
  {
    id: 3,
    label: 'label',
    help_text: 'this is a help text',
    field_type: 'text',
    col_width: 4,
    name: 'form-element',
    title: 'Input Text',
    description: 'des',
    placeholder: 'placeholder',
  },
  {
    id: 4,
    name: 'form-element',
    field_type: 'textarea',
    title: 'Textarea Title',
    label: 'Textarea Label',
    col_width: 4,
    value: '',
    description: 'Multiple input text area',
    helperText: 'Helper Text',
    placeholder: 'Enter description here...',
  },
  // {
  //   id: 5,
  //   name: 'form-element',
  //   description: 'Image Upload',
  //   field_type: 'image',
  //   title: 'Profile Picture',
  //   label: 'Profile Picture Label',
  //   col_width: '4',
  //   value: '',

  //   helperText: 'Upload Image file type of jpg/png only . Max size 1024kb',
  //   placeholder: 'Enter description here...',
  //   status: true,
  //   draggable: true,
  //   index: null,
  // },
  {
    id: 6,
    name: 'form-element',
    field_type: 'evidence',
    title: 'Evidence Block',
    label: 'Evidence Block',
    profile_img_url: '',
    profile_img_helper_text: 'Upload Image file type of jpg/png only . Max size 1024kb',
    placeholder: 'Enter description here...',
    profile_description: '',
  },
  // dropdown
  {
    id: 7,
    name: 'form-element',
    field_type: 'dropdown',
    title: 'Dropdown Block',
    label: 'Dropdown Block',
    // profile_img_url: '',
    // profile_img_helper_text: 'Upload Image file type of jpg/png only . Max size 1024kb',
    // placeholder: 'Enter description here...',
    // profile_description: '',
  },

  // MCQ
  {
    id: 8,
    name: 'form-element',
    field_type: 'single_select_mcq',
    title: 'Quiz Block (Single Select)',
    label: 'Quiz Block',
    // profile_img_url: '',
    // profile_img_helper_text: 'Upload Image file type of jpg/png only . Max size 1024kb',
    // placeholder: 'Enter description here...',
    // profile_description: '',
  },
  {
    id: 9,
    name: 'form-element',
    field_type: 'behavior_block_type1',
    title: 'Behavior Block (Type-1)',
    label: 'How would you rate your behaviour on this task',
    rating_label: 'Score:',
    plan_label: 'Development Plan',
    description: 'Rating and development',
    helperText: 'Helper Text',
    placeholder: 'Enter description here...',
  },
  {
    id: 10,
    name: 'form-element',
    field_type: 'behavior_block_type2',
    title: 'Behavior Block (Type-2)',
    label: 'How would you rate your behaviour on this task',
    rating_label: 'Score:',
    plan_label: 'Development Plan',
    review_label: 'Review',
    description: 'Rating and development',
    helperText: 'Helper Text',
    placeholder: 'Enter description here...',
  },
];
const initialState = {
  activeEle: '',
  fields,
  mode: 'view',
  elements: [],
  loading: false,
  isDraged: false,
  form: {},
};

export const formBuilderSlice = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    setActiveEle: (state, action) => {
      return { ...state, activeEle: action.payload };
    },
    setIsDraged: (state, action) => {
      return { ...state, isDraged: action.payload };
    },
    setFields: (state, action) => {
      return { ...state, fields: action.payload };
    },
    setElements: (state, action) => {
      return { ...state, elements: action.payload };
    },
    addElement: (state, action) => {
      return { ...state, elements: [...state.elements, action.payload] };
    },
    addElements: (state, action) => {
      return { ...state, elements: [...state.elements, ...action.payload] };
    },
    setMode: (state, action) => {
      return { ...state, mode: action.payload };
    },
    setForm: (state, action) => {
      return { ...state, form: action.payload };
    },
    updateElement: (state, action) => {
      // console.log(action.payload);
      let elementsClone = [...state.elements];
      let obj = elementsClone.find((item) => item.id === action.payload.id);
      let index = elementsClone.indexOf(obj);
      elementsClone[index] = action.payload;
      // console.log(obj);
      // console.log(index);
      return { ...state, elements: [...elementsClone] };
    },
    deleteElement: (state, action) => {
      return { ...state, elements: state.elements.filter((item) => item.id !== action.payload) };
    },
  },
});

export const { setActiveEle, setFields, setForm, setElements, addElement, setMode, deleteElement, addElements, setIsDraged, updateElement } = formBuilderSlice.actions;
export default formBuilderSlice.reducer;
